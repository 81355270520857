/**
* Mixins
*/

// Sass rem
// https://github.com/pierreburel/sass-rem
// Sass MQ
// https://github.com/sass-mq/sass-mq
// https://sass-mq.github.io/sass-mq/#undefined-mixin-mq

@import "~sass-rem/rem";
@import "~sass-mq/mq";

// container
@mixin container-breakpoint() {
  width: 100%;

  @each $breakpoint, $width in $container-max-width {
    @include mq($from: $breakpoint) {
      max-width: $width;
    }
  }
}

@mixin full-fit($position: absolute) {
  position: $position;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

// centering method
@mixin center-method($position: absolute) {
  position: $position;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: auto;
}

// flexbox centering
@mixin center-flex() {
  display: flex;
  align-items: center;
  justify-content: center;
}

// css3 force 3D
@mixin force3D() {
  transform: translate3d(0, 0, 0);
  backface-visibility: hidden;
}

// get vw font size
@function get-vw($size, $viewport: 350) {
  $rate: 100 / $viewport;

  @return $rate * $size * 1vw;
}

// font size view width
@mixin font-vw($font_size: 16px) {
  font-size: $font_size * 1;
  font-size: get-vw($font_size);
}

// add material icon
@mixin materialicons() {
  font-size: 0;
  transition: opacity 0.3s ease;
  opacity: 0;

  .wf-materialicons-n4-active & {
    font-family: $font-materialicon;
    font-size: 1em;
    font-weight: normal;
    font-style: normal;
    line-height: 1;
    display: inline-block;
    visibility: visible;
    white-space: nowrap;
    letter-spacing: normal;
    text-transform: none;
    word-wrap: normal;
    opacity: 1;
    direction: ltr;
    -webkit-font-smoothing: antialiased;
    text-rendering: optimizeLegibility;
    -moz-osx-font-smoothing: grayscale;
    font-feature-settings: "liga";
  }
}

// generate border triangle
@mixin triangle($direction, $color: currentcolor, $size: 1em) {
  $size: $size/2;
  $transparent: rgba($color, 0);
  $opposite: (
    top: bottom,
    right: left,
    left: right,
    bottom: top
  );

  display: inline-block;
  width: 0;
  height: 0;
  content: "";
  border: $size solid transparent;
  border-#{map-get($opposite, $direction)}-color: $color;
  margin-#{$direction}: -$size/2;
}

@mixin icon-arrow($size: 6px, $color: #fff, $direction: right) {
  position: absolute;
  top: 50%;
  width: rem($size);
  height: rem($size);
  content: "";
  border-top: 2px solid $color;

  @if ($direction == right) {
    transform: translate(0, -50%) rotate(45deg);
    border-right: 2px solid $color;
  }

  @if ($direction == left) {
    transform: translate(0, -50%) rotate(-45deg);
    border-left: 2px solid $color;
  }
}

// set IE fallback inline Svg style
@mixin svgRatio($ratio, $el: "& > svg") {
  &::before {
    display: block;
    padding-top: $ratio;
    content: "";
  }

  #{$el} {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}

// generate card widh
@mixin make-card-size($prefix: null, $column: $columns) {
  @for $i from 1 through $column {
    $width: percentage($i / $column);

    @if ($prefix) {
      &--#{$prefix}-#{$i} {
        @include mq($from: $prefix) {
          flex: 0 0 calc(#{$width} - #{$spacer});
          // max-width: percentage($i / $column);
          max-width: calc(#{$width} - #{$spacer});
        }
      }
    }

    @else {
      &--#{$i} {
        flex: 0 0 calc(#{$width} - #{$spacer});
        // max-width: percentage($i / $column);
        max-width: calc(#{$width} - #{$spacer});
      }
    }
  }
}

@mixin excerpt($line-height, $font-size, $lines, $color) {
  line-height: $line-height;
  position: relative;
  overflow: hidden;
  height: $font-size * $line-height * $lines + px;
  text-align: justify;
  word-break: break-all;

  &::before,
  &::after {
    position: absolute;
  }

  &::before {
    top: $font-size * $line-height * ($lines - 1) + px;
    right: 0;
    width: 1em !important;
    content: "...";
    text-align: center;
    background-color: $color;
  }

  &::after {
    z-index: 2;
    width: 100%;
    height: 100%;
    content: "";
    background: 0;
    background-color: $color;
  }
}

// Pure CSS multi line text with ellipsis
// https://codepen.io/martinwolf/pen/qlFdp

@mixin multi-line-ellipsis(
  $font-size: rem(16px),
  $line-height: 1.6,
  $lines-to-show: 2
) {
  font-size: $font-size;
  line-height: $line-height;
  display: block; /* Fallback for non-webkit */
  display: -webkit-box;
  overflow: hidden;
  -webkit-box-orient: vertical;
  max-width: 100%;
  height: $font-size * $line-height * $lines-to-show; /* Fallback for non-webkit */
  margin: 0 auto;
  text-overflow: ellipsis;
  -webkit-line-clamp: $lines-to-show;
}

// set flex-wrap row
@mixin set-row {
  display: flex;
  flex-wrap: wrap;
}

// set flex-wrap column
@mixin set-col($width: 100%) {
  flex: 0 0 $width;
  width: 100%;
  max-width: $width;
}

// bg cover thumbnail
@mixin bg-thumbnail($percentage: 100%, $size: cover, $display: block) {
  display: $display;
  background-repeat: no-repeat;
  background-position: center;
  background-size: $size;

  &::before {
    display: block;
    padding-top: $percentage;
    content: "";
  }
}

@mixin thumbnail-obj-fit(
  $percentage: 100%,
  $size: cover,
  $position: center,
  $bg-color: $color-bg-circle
) {
  position: relative;
  z-index: 1;
  overflow: hidden;
  padding-top: $percentage;
  transform: translate3d(0, 0, 0);
  background-color: $bg-color;

  & > img {
    @include center-method;

    position: relative;
    display: block;
    width: 100%;
    height: auto;
    transform: translate3d(0, 0, 0);

    @supports (object-fit: $size) {
      position: absolute;
      object-fit: $size;
      height: 100%;
      object-position: center;
    }
  }
}

@mixin card(
  $color: $color-text,
  $shadow: $box-shadow-card-lg,
  $transition: box-shadow 0.4s ease
) {
  text-decoration: none;
  color: $color;

  &::after {
    @include center-method;

    z-index: 0;
    content: "";
    transition: $transition;
    pointer-events: none;
  }

  &:hover {
    text-decoration: none;
    color: $color;

    & h2,
    & h3,
    & h4,
    & p {
      opacity: $hover-opacity;
    }

    &::after {
      @include center-method;

      content: "";
      box-shadow: $box-shadow-card-lg;
    }
  }
}

@mixin list-num(
  $size: 46px,
  $bg-color: $color-main,
  $color: $color-base,
  $position: absolute
) {
  @include font-tsukushi($bold: true);

  font-size: rem(22px);
  line-height: $size;
  position: $position;
  width: $size;
  height: $size;
  content: counter(number);
  counter-increment: number;
  text-align: center;
  vertical-align: middle;
  color: $color;
  border-radius: 50%;
  background-color: $bg-color;
}

@mixin badge($font-size: 12px) {
  font-size: rem($font-size);
  display: inline-block;
  padding: 0.125em 0.75em;
  color: $color-base;
  background-color: $color-main;
}

@mixin truncate($line-clamp: 3) {
  display: -webkit-box;
  overflow: hidden;
  -webkit-box-orient: vertical;
  margin-bottom: 0;
  -webkit-line-clamp: $line-clamp;
}

// 授業日時スタイル
@mixin dow($font-size: 14px, $bg-color: $color-main-light) {
  font-size: rem($font-size);
  font-weight: $font-weight-bold;
  line-height: $font-size * 1.6;
  position: relative;
  top: -$font-size * 0.2;
  display: inline-block;
  width: $font-size * 1.6;
  height: $font-size * 1.6;
  margin-left: 0.5em;
  text-align: center;
  border-radius: 50%;
  background-color: $color-main-light;
}

@mixin classes-status {
  // 受付中
  &.is-status-active {
    color: $color-base;
    background-color: $color-main;
  }

  // 満席
  &.is-status-fully {
    color: lighten($color-text, 50%);
  }

  // 残りわずか
  &.is-status-few-left {
    color: $color-text-warning;
  }
}

@mixin grad-bar($position: left, $width: 1.25em, $height: 5px) {
  position: absolute;
  right: 0;
  bottom: 0;
  width: $width;
  height: $height;
  content: "";
  background-image: $color-main-grad;

  @if $position == left {
    left: 0;
  }

  @if $position == right {
    right: 0;
  }

  @if $position == center {
    right: 0;
    left: 0;
    margin-right: auto;
    margin-left: auto;
  }
}
