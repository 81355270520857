@charset "UTF-8";
/**
旧 common.css 修正版

旧シブ大サイトからの流用ページ互換用に css/common.css を scss 化し、不要箇所などを修正したバージョンです。

* CSS 2 SASS/SCSS CONVERTER
https://css2sass.herokuapp.com/
*/
/**
 * Variables
 */
/**
* Mixins
*/
/**
 * Functions
 */
/* --------------------------------
	font-size (default : 14px)

		10px ： 72%
		11px ： 79%
		12px ： 86%
		13px ： 93%
		14px ： 100%
		15px ： 108%
		16px ： 115%
		17px ： 122%
		18px ： 129%
		19px ： 136%
		20px ： 143%
		21px ： 150%
		22px ： 158%
		23px ： 165%
		24px ： 172%
		25px ： 179%
		26px ： 186%
		28px ： 200%
		30px ： 214%
-------------------------------- */
/* ============================================================

	style info : ブラウザごとの初期スタイルをリセット

============================================================ */
/* ============================================================

	style info : 全体で汎用的に使用できるスタイルを記述

============================================================ */
/* --------------------------------
	clearfix (.cf)
-------------------------------- */
.cf::after {
  font-size: 0;
  display: block;
  clear: both;
  content: "";
}

/* --------------------------------
	image replace (.ir)
-------------------------------- */
.ir {
  font-size: 0;
  display: block;
  overflow: hidden;
  text-align: left;
  color: transparent;
}

/* --------------------------------
	property
-------------------------------- */
/* width */
/* pic回り込み */
.pic-left {
  float: left;
  margin-right: 20px !important;
  margin-bottom: 20px;
}

.pic-right {
  float: right;
  margin-bottom: 20px;
  margin-left: 20px !important;
}

.pic-clear {
  clear: both;
  height: 0;
  margin: 0;
  padding: 0;
  border: none;
}

.pic-left + *,
.pic-right + * {
  overflow: hidden;
}

@media (max-width: 47.99em) {
  .pic-left,
  .pic-right {
    float: none;
    margin-right: 0 !important;
    margin-left: 0 !important;
    text-align: center;
  }
}
/* ============================================================

	style info : サイト全体で共通のスタイルを記述

============================================================ */
/* -----------------------------------
	basic
----------------------------------- */
br.pc {
  display: block;
}
br.sp {
  display: none;
}

img.icon {
  vertical-align: middle;
}

@media (max-width: 47.99em) {
  body {
    min-width: inherit !important;
  }
  img {
    max-width: 100%;
    height: auto;
  }
  img.icon {
    width: auto;
  }
  /*
  div {
  	text-align: justify;
  }
  */
  br.pc {
    display: none;
  }
  br.sp {
    display: block;
  }
  /*
  	.pcOnly {
  		min-width: 1020px;
  	}
  	.pcOnly img {
  		width: auto;
  	}
  	.pcOnly br.pc {
  		display: block;
  	}
  	.pcOnly br.sp {
  		display: none;
  	}
  */
}
/* -----------------------------------
	layout
----------------------------------- */
.layoutInner {
  width: 100%;
  display: block;
  margin-right: auto;
  margin-left: auto;
  padding-right: 1rem;
  padding-left: 1rem;
}
@media (min-width: 36em) {
  .layoutInner {
    max-width: 540px;
  }
}
@media (min-width: 48em) {
  .layoutInner {
    max-width: 720px;
  }
}
@media (min-width: 62em) {
  .layoutInner {
    max-width: 960px;
  }
}
@media (min-width: 75em) {
  .layoutInner {
    max-width: 1140px;
  }
}
.layoutInner a {
  text-decoration: underline;
}

#contents {
  position: relative;
  z-index: 100;
  padding-top: 30px;
  padding-bottom: 60px;
}
#contents ol,
#contents ul {
  padding-left: 0;
  list-style: none;
}

/*
	.pcOnly .layoutInner {
		width: 1000px;
		margin: 0 auto;
	}

	.pcOnly #main {
		display: inline;
		float: left;
		width: 740px;
	}
	.pcOnly #sub {
		display: inline;
		float: right;
		width: 200px;
	}
*/
/* screen and (max-width:640px) */
/* -----------------------------------
	parts > title
----------------------------------- */
.basicTitle {
  font-size: 115%;
  line-height: 1.4;
  margin-bottom: 10px;
  padding: 5px 10px;
  letter-spacing: 0;
  background: #ebebeb;
}
@media (min-width: 48em) {
  .basicTitle {
    font-size: 122%;
    margin-bottom: 20px;
    padding: 10px;
    letter-spacing: 1px;
  }
}

.basicTitle02 {
  font-size: 115%;
  margin-bottom: 10px;
  letter-spacing: 0;
  color: #222;
}
@media (min-width: 48em) {
  .basicTitle02 {
    margin-bottom: 20px;
    letter-spacing: 1px;
  }
}

.basicTitle03 {
  font-size: 122%;
  margin-bottom: 15px;
  letter-spacing: 1px;
  color: #00a350;
}

.basicTitle04 {
  font-size: 129%;
  line-height: 1.5;
  margin-bottom: 10px;
  padding-left: 10px;
  letter-spacing: normal;
  border-left: 5px solid #00b458;
}
@media (min-width: 48em) {
  .basicTitle04 {
    font-size: 143%;
    margin-bottom: 20px;
    padding-left: 20px;
    border-left: solid 10px #00b458;
  }
}

/* -----------------------------------
	parts > button
----------------------------------- */
.basicButton {
  font-size: 115%;
  font-weight: bold;
  line-height: 50px;
  display: inline-block;
  width: 100%;
  height: 50px;
  padding-left: 10px;
  cursor: pointer;
  text-align: center;
  text-decoration: none !important;
  color: #fff;
  border: none;
  border-radius: 12px;
  background: #00b458 30px center no-repeat;
  background-size: 7px;
}
@media (min-width: 48em) {
  .basicButton {
    line-height: 64px;
    width: 290px;
    height: 64px;
    background-image: url(../../img/common/arw_basic_wht_r.png);
  }
}
p.button .basicButton {
  position: relative;
  z-index: 1;
  background-color: transparent;
}
p.button .basicButton:hover {
  color: #fff;
}
@media (max-width: 47.99em) {
  .basicButton a.basicButton {
    width: 97%;
  }
}

.ieOld .basicButton {
  display: inline;
  zoom: 1;
}

.basicButton02 {
  font-size: 86%;
  font-weight: bold;
  line-height: 27px;
  display: inline-block;
  width: 89px;
  height: 27px;
  padding: 0 19px 0 29px;
  text-align: center;
  text-decoration: none;
  color: #fff;
  background: #00b458 url(../../img/common/arw_basic_wht02_r.png) 12px center no-repeat;
}

.ieOld .basicButton02 {
  display: inline;
  zoom: 1;
}

.basicButton03 {
  font-size: 79%;
  font-weight: bold;
  line-height: 22px;
  display: inline-block;
  margin: 0 0 10px;
  padding: 0 8px 0 24px;
  text-decoration: none !important;
  color: #fff;
  border: none;
  border-radius: 2px;
  background: #00b458 url(../../img/common/arw_basic_wht02_r.png) no-repeat 9px center;
}

.ieOld .basicButton03 {
  display: inline;
  zoom: 1;
}

.blankButton {
  font-size: 108%;
  font-weight: bold;
  line-height: 65px;
  display: inline-block;
  padding: 0 41px 0 53px;
  text-decoration: none !important;
  letter-spacing: 0.1em;
  color: #fff;
  border: none;
  border-radius: 12px;
  background: #00b458 url(../../img/common/icn_blank_wht.png) no-repeat 22px center;
}

.ieOld .blankButton {
  display: inline;
  zoom: 1;
}

/* screen and (max-width:640px) */
/* -----------------------------------
	parts > text
----------------------------------- */
.caution {
  padding-left: 1em;
  text-indent: -1em;
}

.important {
  color: #de6f5b;
}

.note {
  font-size: 86%;
}

/* status */
.status {
  font-size: 79%;
  font-weight: bold;
  line-height: 1.2;
  display: inline-block;
  padding-bottom: 4px;
  color: #00a350;
  border-bottom: solid 2px #00b458;
}

/* -----------------------------------
	parts > link
----------------------------------- */
.blockLink {
  position: relative;
  display: block;
  zoom: 1;
  cursor: pointer;
}

.arwLink a,
.arwLink span {
  font-size: 86%;
  font-weight: bold;
  padding-left: 13px;
  text-decoration: none;
  color: #333 !important;
  background: url(../../img/common/arw_basic_grn02_r.png) 0 0 no-repeat;
}

.backLink a {
  /* color: #00b458 !important; */
  font-weight: bold;
  padding-left: 20px;
  text-decoration: none;
  letter-spacing: 1px;
  background: url(../../img/common/arw_basic_grn03_r.png) 0 0.2em no-repeat;
}

.referLink a,
.referLink span {
  padding-left: 14px;
  text-decoration: none;
  color: #00b458 !important;
  background: url(../../img/common/arw_basic_grn04_r.png) 0 0.3em no-repeat;
}

.moreLink img {
  position: relative;
  top: 1px;
  display: inline !important;
  margin: 0 !important;
  vertical-align: baseline;
}

@media (max-width: 47.99em) {
  .arwLink a,
  .arwLink span {
    padding-left: 12px;
    background: url(../../img/common/sp/arw_basic_grn_r.png) left center no-repeat;
    background-size: 5px;
  }
  .backLink a {
    padding-left: 12px;
    background: url(../../img/common/sp/arw_basic_grn_l.png) left center no-repeat;
    background-size: 5px;
  }
  .moreLink img {
    width: 12px;
  }
}
/* screen and (max-width:640px) */
/* -----------------------------------
	parts > video
----------------------------------- */
.video {
  position: relative;
  width: 100%;
  padding-top: 56.25%;
}
.video iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100% !important;
  height: 100% !important;
}

/* -----------------------------------
	parts > list
----------------------------------- */
ul.basicList li {
  margin: 0 0 1.5em 1.5em;
  list-style: disc outside;
}

ol.basicList li {
  margin: 0 0 1.5em 1.5em;
  list-style: decimal outside;
}

ul.arwList li {
  padding-left: 15px;
  background: url(../../img/common/arw_basic_grn_r.png) 0 0.5em no-repeat;
}

dl.cautionList {
  line-height: 1.6;
}
dl.cautionList dt {
  width: 3em;
}
dl.cautionList dd {
  margin: -1.6em 0 1em 3em;
}

ol.circleNoList li {
  padding-left: 35px;
  list-style: none;
}
ol.circleNoList li.no01 {
  background: url(../../img/common/icn_circle_no01.png) no-repeat left top;
}
ol.circleNoList li.no02 {
  background: url(../../img/common/icn_circle_no02.png) no-repeat left top;
}
ol.circleNoList li.no03 {
  background: url(../../img/common/icn_circle_no03.png) no-repeat left top;
}

@media (max-width: 47.99em) {
  ul.basicList,
  ol.basicList {
    margin-right: 5px;
  }
  ul.basicList li,
  ol.basicList li {
    margin-bottom: 1em;
  }
}
/* screen and (max-width:640px) */
/* -----------------------------------
	parts > nav
----------------------------------- */
/* moreNav */
.moreNav {
  font-size: 79%;
  position: absolute;
  top: 30px;
  right: 2px;
}
.moreNav a {
  padding: 2px 0 2px 15px;
  text-decoration: none;
  background: url(../../img/common/arw_basic_grn02_r.png) 0 0.2em no-repeat;
}
.moreNav li {
  float: left;
  margin-left: 15px;
}

a.project_blog_link {
  font-size: 79%;
  padding: 2px 0 2px 15px;
  text-decoration: none;
  background: url(../../img/common/arw_basic_grn02_r.png) 0 0.2em no-repeat;
}

/* localNav */
ul.localNav {
  margin-bottom: 15px;
  border-left: solid 3px #efefef;
}
ul.localNav li {
  float: left;
  width: 181px;
  border-right: solid 3px #efefef;
}
ul.localNav li a {
  font-size: 86%;
  font-weight: bold;
  line-height: 34px;
  display: block;
  height: 34px;
  text-align: center;
  text-decoration: none;
  letter-spacing: 1px;
  color: #222 !important;
}
ul.localNav li a span {
  padding: 1px 0 1px 18px;
  background: url(../../img/common/arw_basic_grn03_r.png) left center no-repeat;
}
ul.localNav li a.current {
  color: #00b458 !important;
}
ul.localNav li a.current span {
  padding: 1px 0 1px 20px;
  background: url(../../img/common/arw_basic_grn03_b.png) left center no-repeat;
}
ul.pager {
  margin: 0 auto;
  text-align: center;
  letter-spacing: -0.4em;
}
ul.pager li {
  line-height: 1;
  display: inline-block;
  margin: 0 6px 7px 0;
  letter-spacing: normal;
  color: #acacac;
}
ul.pager li a,
ul.pager li em {
  font-weight: normal;
  display: block;
  padding: 10px 9px;
}

/* pager */
.ie ul.pager li {
  display: inline;
  zoom: 1;
}
.ie ul.pager li a,
.ie ul.pager li em {
  display: inline;
  zoom: 1;
}

ul.pager li a {
  color: #00b458 !important;
  border: 1px solid #00b458;
}
ul.pager li a:hover {
  color: #fff !important;
  background-color: #00b458;
}
ul.pager li.next {
  margin: 0;
}
ul.pager li.prev a, ul.pager li.next a, ul.pager li.prev em, ul.pager li.next em {
  font-size: 86%;
  padding: 12px 9px 10px 9px;
}
ul.pager li em {
  border: 1px solid #acacac;
}

.keywordSearch input {
  font-size: 93%;
  line-height: 34px;
  padding: 0 10px;
  vertical-align: top;
}
.keywordSearch input.text {
  height: 34px;
  border: solid 1px #ddd;
}

/* pagerLight */
ul.pagerLight {
  overflow: hidden;
  letter-spacing: -0.4em;
}
ul.pagerLight li {
  display: inline-block;
  letter-spacing: normal;
}

.ieOld ul.pagerLight li {
  display: inline;
  zoom: 1;
}

ul.pagerLight li.prev {
  background: url(../../img/common/bdr_pagerlight.png) right 0.2em no-repeat;
}
ul.pagerLight li a {
  font-weight: bold;
  text-decoration: none;
  color: #00b458 !important;
}
ul.pagerLight li.prev a {
  padding: 5px 21px 5px 30px;
  background: url(../../img/common/arw_circle_grn_l.png) 0 0.2em no-repeat;
}
ul.pagerLight li.prev em {
  padding: 5px 21px 5px 30px;
  color: #acacac;
  background: url(../../img/common/arw_circle_grn_l_off.png) 0.2em no-repeat;
}
ul.pagerLight li.next a {
  padding: 5px 30px 5px 21px;
  background: url(../../img/common/arw_circle_grn_r.png) right 0.2em no-repeat;
}
ul.pagerLight li.next em {
  padding: 5px 30px 5px 21px;
  color: #acacac;
  background: url(../../img/common/arw_circle_grn_r_off.png) right 0.2em no-repeat;
}

/* navTop */
.navTop {
  display: block;
  margin-bottom: 25px;
}
.navTop .keywordSearch {
  display: inline;
  float: right;
}
.navTop .keywordSearch input.text {
  width: 135px;
}

.ieOld .navTop .keywordSearch input.text {
  width: 135px;
}

.navTop ul.pager {
  display: block;
  text-align: left;
}

/* navBtm */
.navBtm {
  margin-top: 25px;
}

@media (max-width: 47.99em) {
  /* moreNav */
  .moreNav {
    font-size: 86%;
    position: relative;
    top: auto;
    right: auto;
  }
  .moreNav a {
    font-weight: bold;
    display: block;
    padding: 10px 10px 10px 20px;
    text-align: center;
    text-decoration: none;
    border: solid 2px #e7e7e7;
    border-radius: 3px;
    background: #eee url(../../img/common/sp/arw_basic_grn_r.png) 10px center no-repeat;
    background-size: 7px;
  }
  .moreNav li {
    float: left;
    width: 49%;
    margin: 0;
  }
  .moreNav li:first-child {
    margin-right: 2%;
  }
  /* localNav */
  ul.localNav {
    margin-right: -2%;
    margin-bottom: 10px;
    border-left: none;
  }
  ul.localNav li {
    float: left;
    width: 48%;
    margin: 0 2% 5px 0;
    border-right: none;
  }
  ul.localNav li a {
    line-height: 34px;
    display: block;
    height: 34px;
    letter-spacing: 0;
    border: solid 2px #ccc;
    border-radius: 5px;
    background: #eee;
  }
  ul.localNav li a.current {
    background: #fff;
  }
  ul.localNav li a span {
    padding: 1px 0 1px 12px;
  }
  ul.localNav li a.current span {
    padding: 1px 0 1px 16px;
  }
  ul.pager {
    font-size: 100%;
  }
  ul.pager li {
    margin: 0 5px 5px 0;
  }
  ul.pager li a,
  ul.pager li em {
    padding: 5px 6px;
  }
  ul.pager li.prev a, ul.pager li.next a, ul.pager li.prev em, ul.pager li.next em {
    font-size: 100%;
    padding: 5px 6px;
  }
  ul.pager li.prev span, ul.pager li.next span {
    display: none;
  }
  ul.pagerLight li.prev a,
  ul.pagerLight li.prev em {
    padding-right: 10px;
  }
  ul.pagerLight li.next a,
  ul.pagerLight li.next em {
    padding-left: 10px;
  }
  ul.pagerLight li.prev a,
  ul.pagerLight li.prev em {
    background-size: 16px;
  }
  ul.pagerLight li.next a,
  ul.pagerLight li.next em {
    background-size: 16px;
  }
  /* pager */
  /* pagerLight */
  .keywordSearch input {
    vertical-align: middle;
  }
  .keywordSearch input.text {
    width: 70%;
  }
  /* navTop */
  .navTop {
    margin-bottom: 15px;
  }
  .navTop .keywordSearch {
    display: block;
    float: none;
  }
  .ieOld .navTop .keywordSearch input.text {
    width: 155px;
  }
  .navTop ul.pager {
    display: block;
    float: none;
    width: auto;
    margin-top: 15px;
    text-align: center;
  }
}
/* screen and (max-width:640px) */
/* -----------------------------------
	socialButtons
----------------------------------- */
ul.socialButtons {
  letter-spacing: -0.5em;
}
ul.socialButtons > * {
  display: inline-block;
  vertical-align: top;
  letter-spacing: normal;
}
ul.socialButtons .twitter {
  margin-right: 1em;
}
ul.socialButtons .facebook {
  line-height: 0;
  /*	position: relative;
  top: -6px; */
}

@media screen and (-webkit-min-device-pixel-ratio: 0) {
  ul.socialButtons li.facebook {
    top: 0;
  }
}
/* -----------------------------------
	header
----------------------------------- */
#header {
  position: relative;
  z-index: 200;
  margin-bottom: 43px;
  border-bottom: solid 1px #ececec;
  background: #f7f7f7;
}
#header a {
  text-decoration: none;
}
#header .siteLogo {
  display: inline;
  float: left;
}

#loginNav {
  font-size: 79%;
  font-weight: bold;
  float: right;
  margin: 20px 50px 0 0;
  text-align: right;
}
#loginNav.member {
  margin-top: 12px;
}
#loginNav a {
  margin-left: 1.2em;
  padding-left: 12px;
  background: url(../../img/common/arw_min_blk_r.png) 0 0.2em no-repeat;
}

#header ul#subNav {
  display: inline;
  float: right;
}
#header ul#subNav li {
  display: inline;
  float: left;
  margin-left: 4px;
}
#header ul#subNav li a {
  font-size: 79%;
  font-weight: bold;
  line-height: 32px;
  display: block;
  height: 32px;
  text-align: center;
}
#header ul#subNav li.company a {
  width: 128px;
  color: #fff;
  background: #00b458;
}
#header ul#subNav li.lang a {
  width: 75px;
  color: #5d5d5d;
  background: #ddd;
}

/* loginPanel */
/* loginPanel */
/* screen and (max-width:640px) */
/* -----------------------------------
	footer
----------------------------------- */
/* screen and (max-width:640px) */
/* -----------------------------------
	extra
----------------------------------- */
#extra {
  margin-top: 40px;
}
#extra .support {
  padding-top: 21px;
  background: url(../../img/common/bg_extra.png) 950px 0 no-repeat;
}
#extra .support .inner {
  padding: 20px 25px 22px 25px;
  background: #00b458;
}
#extra .support h2 {
  font-size: 129%;
  font-weight: bold;
  margin-bottom: 15px;
  color: #fff;
}
#extra .support ul {
  position: relative;
  margin-right: -10px;
}
#extra .support ul li {
  display: inline;
  float: left;
  margin-right: 10px;
}
#extra .support ul li a {
  font-size: 108%;
  font-weight: bold;
  line-height: 65px;
  display: block;
  width: 220px;
  height: 65px;
  padding-left: 10px;
  text-align: center;
  text-decoration: none;
  color: #02964a;
  background: #fff url(../../img/common/arw_basic_grn02_r.png) 15px center no-repeat;
}
#extra .facebookWidget {
  float: left;
}
#extra .twitterWidget {
  float: right;
}
#extra .facebookWidget,
#extra .twitterWidget {
  width: 493px;
  margin-top: 25px;
}
#extra .facebookWidget iframe {
  vertical-align: top;
}
#extra .twitterWidget iframe {
  box-sizing: border-box;
  vertical-align: top;
  border: solid 1px #e9ebee !important;
}

@media (max-width: 47.99em) {
  #extra {
    margin-top: 0;
  }
  #extra .support {
    padding-top: 0;
    background: none;
  }
  #extra .support .inner {
    margin: 0;
    padding: 15px 10px 10px 10px;
  }
  #extra .support h2 {
    font-size: 100%;
    font-weight: bold;
    margin-bottom: 15px;
    color: #fff;
  }
  #extra .support ul {
    position: relative;
    margin: 0 -5px -5px -5px;
  }
  #extra .support ul li {
    width: 48%;
    margin: 0 0 5px 0;
    padding: 0 1%;
  }
  #extra .support ul li a {
    font-size: 79%;
    line-height: 4em;
    width: auto;
    height: 4em;
    padding-left: 7px;
    background: #fff url(../../img/common/sp/arw_basic_grn_r.png) 7px center no-repeat;
    background-size: 5px;
  }
  #extra .facebookWidget,
  #extra .twitterWidget {
    display: none;
    float: none;
    width: 100%;
    margin-top: 20px;
  }
}
/* screen and (max-width:640px) */
/* -----------------------------------
	sub
----------------------------------- */
/* globalNav */
ul.globalNav {
  border-top: solid 2px #efefef;
}
ul.globalNav li {
  border-bottom: solid 2px #efefef;
}
ul.globalNav li.news {
  display: none;
}
ul.globalNav li ul {
  margin: 0 0 10px 41px;
}
ul.globalNav li.manabu a:hover, ul.globalNav li.asobu a:hover, ul.globalNav li.tsukuru a:hover {
  opacity: 1;
}
ul.globalNav li ul li {
  margin: 0 0 5px 0;
  list-style: disc outside;
  color: #333;
  border-bottom: none;
}
ul.globalNav li ul li a {
  font-size: 79%;
  font-weight: bold;
  line-height: normal;
  height: auto;
  text-decoration: none;
  color: #444;
  background: none;
}
ul.globalNav li ul li a span {
  padding: 0;
}
ul.globalNav li ul li.current {
  color: #09ad59;
}
ul.globalNav li ul li.current a {
  color: #09ad59;
}

/* tools */
#tools {
  margin-top: 25px;
  background: #f2f2f2;
}
#tools li a {
  font-size: 93%;
  font-weight: bold;
  display: block;
  padding: 22px 10px 22px 60px;
  text-decoration: none;
  letter-spacing: 1px;
}
#tools li.map a {
  background: url(../../img/common/icn_map.png) 19px center no-repeat;
}

/*
#tools li {
	display: inline;
	float: left;
}
*/
/*
#tools li.calendar {
	margin-right: 10px;
}
#tools li.calendar a {
	background: url(../../img/common/icn_calendar.png) left top no-repeat;
}
*/
/* information */
#information ul {
  font-size: 86%;
  line-height: 1.5;
  margin: 10px 0 5px 0;
}
#information ul li {
  padding: 0 14px;
}
#information ul li a {
  text-decoration: none;
}
#information ul li span {
  font-size: 84%;
  display: block;
  margin-top: 2px;
}
#information .arwLink {
  margin: 0 14px 10px;
  text-align: right;
}

/* banner */
#sub ul.banner {
  margin: 25px 0;
}
#sub ul.banner li {
  margin-top: 12px;
}
#sub .external_link ul.banner li {
  text-align: center;
}
#sub .carousel,
#sub .gakucho_blog,
#sub .external_link {
  font-size: 93%;
  margin-top: 25px;
  border: solid 1px #ddd;
}
#sub .carousel h2,
#sub .gakucho_blog h2,
#sub .external_link h2 {
  padding: 8px;
  text-align: center;
  color: #222;
  border-bottom: solid 1px #ddd;
}
#sub .carousel .content,
#sub .gakucho_blog .content,
#sub .external_link .content {
  position: relative;
  margin: 11px 15px;
}
#sub .carousel ul.data li {
  display: inline;
  float: left;
  width: 160px;
  padding: 0 4px;
}
#sub .carousel ul.data li p.pic {
  overflow: hidden;
  height: 160px;
  margin-bottom: 10px;
  text-align: center;
}
#sub .carousel ul.data li p.pic img {
  width: 100%;
  height: auto;
}
#sub .carousel ul.nav {
  position: relative;
  height: 21px;
  margin-top: 8px;
}
#sub .carousel ul.nav li {
  position: absolute;
  top: 0;
}
#sub .carousel ul.nav li.prev {
  left: 59px;
}
#sub .carousel ul.nav li.next {
  left: 91px;
}
#sub #storeList {
  background: url(../../img/common/bg_sub_shop.png) right bottom no-repeat;
}
#sub #reportList {
  background: url(../../img/common/bg_sub_report.png) right bottom no-repeat;
}
#sub .gakucho_blog p.pic {
  margin-bottom: 10px;
}
#sub .gakucho_blog p.pic img {
  width: 100%;
  height: auto;
}
#sub .external_link ul.banner li {
  margin: 0 0 11px;
}

@media (max-width: 47.99em) {
  #sub ul.banner {
    margin: 10px -2% 10px 0;
  }
  #sub ul.banner li {
    display: inline;
    float: left;
    width: 48%;
    margin: 0 2% 0 0;
  }
  #sub .external_link ul.banner {
    margin: 10px 0;
  }
  #sub .external_link ul.banner li {
    display: block;
    float: none;
    width: auto;
    margin: 0;
  }
  #sub .external_link ul.banner li img {
    width: auto;
  }
}
/* screen and (max-width:640px) */
/* carousel */
/* reportNotice */
.reportNotice {
  font-size: 93%;
  margin-top: 25px;
  border: solid 1px #ddd;
}
.reportNotice h2 {
  padding: 8px;
  text-align: center;
  color: #222;
  border-bottom: solid 1px #ddd;
}
.reportNotice .content {
  position: relative;
  /* margin: 11px 19px; */
}
.reportNotice .content a {
  display: block;
  padding: 11px 19px;
  text-decoration: none;
}
.reportNotice .content ul li {
  margin-bottom: 10px;
}
.reportNotice .content ul li img {
  width: 100%;
}

/* social widget */
#sub .socialWidget {
  margin-top: 25px;
  border: solid 1px #ddd;
}
#sub .socialWidget .widgetHead {
  padding: 10px 14px;
  border-bottom: solid 1px #ddd;
}
#sub .socialWidget .widgetHead h2 {
  font-size: 12px;
  font-weight: bold;
  line-height: 20px;
  float: left;
}
#sub .socialWidget .widgetHead .button {
  float: right;
}
#sub .socialWidget .widgetHead a {
  text-decoration: none;
}
#sub .socialWidget .widgetBody {
  overflow-x: hidden;
  overflow-y: scroll;
  height: 400px;
}
#sub .facebook .widgetHead .button {
  margin-top: -3px;
}

@media (max-width: 47.99em) {
  #sub {
    margin: 0 -10px;
    padding: 10px;
    /* border-top: solid 3px #efefef; */
  }
  /* globalNav */
  ul.globalNav {
    margin: 20px -10px 0 -10px;
    border-top: solid 1px #efefef;
  }
  #sub ul.globalNav::before {
    font-size: 79%;
    font-weight: bold;
    line-height: 1.2;
    display: block;
    padding: 8px 10px;
    content: "MENU";
    color: #fff;
    background: #00b458;
  }
  ul.globalNav li {
    border-bottom: solid 1px #efefef;
  }
  ul.globalNav li.news {
    display: block;
  }
  ul.globalNav li a {
    display: block;
    padding: 0 10px;
    background: url(../../img/common/sp/arw_basic_grn_r.png) 95% center no-repeat;
    background-size: 9px;
  }
  ul.globalNav li ul {
    margin: 0 0 10px 11%;
  }
  /* tools */
  #tools {
    margin-top: 15px;
    padding: 0;
    background: none;
  }
  #tools li a {
    font-size: 100%;
    display: block;
    padding: 12px 20px 12px 40px;
    text-align: center;
    border: solid 2px #e7e7e7;
    border-radius: 3px;
  }
  #tools li.map a {
    background: #eee url(../../img/common/sp/icn_map.png) 10px center no-repeat;
    background-size: 36px;
  }
  /*
  #tools li {
  	display: inline;
  	float: left;
  	width: 49%;
  }
  */
  /*
  #tools li.calendar {
  	margin-right: 2%;
  }
  #tools li.calendar a {
  	background: #eee url(../../img/common/sp/icn_calendar.png) 6px center no-repeat;
  	background-size: 36px;
  }
  */
  /* information */
  #information {
    display: none;
    margin-top: 0;
    padding-left: 0;
    border-left: none;
  }
  #top #information {
    display: block;
  }
  #information dt {
    font-size: 117%;
    font-weight: bold;
    font-style: normal;
  }
  #information dt a {
    display: block;
    overflow: hidden;
    width: 150px;
    height: 30px;
    vertical-align: top;
    white-space: nowrap;
    text-indent: 200%;
    border: none;
    background-image: url(../../img/common/sp/sprite.png);
    background-position: -155px 0;
    background-size: 305px 389px;
  }
  #information dd {
    font-size: 79%;
    margin: 5px 0;
  }
  /* carousel */
  #sub .carousel {
    margin: 0 0 1px 0;
    padding: 10px;
    border: none;
    background: #eee;
  }
  #sub .carousel h2 {
    margin-bottom: 5px;
    padding: 0;
    text-align: left;
    border-bottom: none;
  }
  #sub .carousel .content {
    margin: 0;
  }
  #sub .carousel ul.data li {
    display: none;
    float: none;
    width: auto;
    height: auto !important;
    padding: 0 20px 0 0;
    background: url(../../img/common/sp/arw_circle_wht_r.png) right center no-repeat;
    background-size: 16px;
  }
  #sub .carousel ul.data li:first-child {
    display: block;
  }
  #sub .carousel ul.data li p.pic {
    float: left;
    width: 35%;
    height: auto;
    margin: 0 10px 0 0;
  }
  #sub .carousel ul.data li p.pic img {
    margin-top: 0 !important;
  }
  #sub .carousel ul.data li h3 {
    font-size: 86%;
    overflow: hidden;
  }
  #sub .carousel ul.nav {
    display: none;
  }
  #sub #storeList {
    background: #eee url(../../img/common/sp/bg_sub_shop.png) right bottom no-repeat;
    background-size: 35px;
  }
  #sub #reportList {
    background: #eee url(../../img/common/sp/bg_sub_report.png) right bottom no-repeat;
    background-size: 35px;
  }
  /* reportNotice */
  .reportNotice {
    margin-top: 10px;
  }
  .reportNotice .content ul li {
    display: none;
    margin: 0 18%;
  }
  .reportNotice .content ul li:first-child {
    display: block;
  }
  .reportNotice .content p.arwLink {
    margin-top: 10px;
    text-align: right;
  }
  /* social widget */
  #sub .socialWidget,
  #sub .gakucho_blog {
    display: none;
  }
  /* gakucho_blog */
}
/* screen and (max-width:640px) */
/* -----------------------------------
	classGridList
----------------------------------- */
ul.classGridList {
  position: relative;
  margin-right: -25px;
}
@media (min-width: 48em) {
  ul.classGridList {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
  }
}
ul.classGridList li {
  line-height: 1.2;
  position: relative;
  display: block;
  margin: 0 0.5em 32px;
}
@media (min-width: 48em) {
  ul.classGridList li {
    flex: 0 0 calc(33.3333333333% - 1em);
    width: 100%;
    max-width: calc(33.3333333333% - 1em);
  }
}
@media (min-width: 62em) {
  ul.classGridList li {
    flex: 0 0 calc(25% - 1em);
    width: 100%;
    max-width: calc(25% - 1em);
  }
}
ul.classGridList li a {
  position: relative;
  display: block;
  height: 100%;
  padding-bottom: 45px;
  text-decoration: none;
  border: solid 1px #dfdfdf;
}
ul.classGridList li p.date {
  position: absolute;
  z-index: 200;
  top: -9px;
  right: -9px;
  width: 58px;
  height: 58px;
  text-align: center;
  color: #fff;
  background: url(../../img/common/bg_date.png) 0 0 no-repeat;
}
ul.classGridList li.ended p.date {
  background: url(../../img/common/bg_date.png) -58px 0 no-repeat;
}
ul.classGridList li p.date span {
  display: block;
}
ul.classGridList li p.date span.year {
  font-family: "Lato", sans-serif;
  font-size: 10px;
  margin-top: 9px;
  letter-spacing: 1px;
}
ul.classGridList li p.date span.year span {
  display: none;
}
ul.classGridList li p.date span.day {
  font-family: "Lato", sans-serif;
  font-size: 16px;
  line-height: 1.1;
}
ul.classGridList li p.date span.day span.slash {
  display: inline;
  margin: 0 2px;
}
ul.classGridList li p.date span.week {
  font-size: 9px;
  margin-top: 1px;
  color: #e4ff00;
}
ul.classGridList li p.dateMulti {
  left: 138px;
  width: 100px;
  padding: 0 2px;
  text-align: left;
  background: url(../../img/common/bg_date_multi.png) 0 0 no-repeat;
}
ul.classGridList li.ended p.dateMulti {
  background: url(../../img/common/bg_date_multi.png) -104px 0 no-repeat;
}
ul.classGridList li p.dateMulti span.fromto {
  display: none;
}
ul.classGridList li p.dateMulti span.start, ul.classGridList li p.dateMulti span.end {
  display: block;
  float: left;
  width: 50px;
  text-align: center;
}
ul.classGridList li p.pic {
  position: relative;
  z-index: 100;
  overflow: hidden;
  height: 155px;
  margin-bottom: 10px;
  border-bottom: solid 1px #dfdfdf;
}
ul.classGridList li p.pic img {
  width: 100%;
}
ul.classGridList li p.status {
  margin: 0 15px 10px 15px;
}
ul.classGridList li.closed p.status {
  color: #7b7b7b;
  border-bottom: solid 2px #7b7b7b;
}
ul.classGridList li.just_rest p.status {
  color: #f28d0b;
  border-bottom: solid 2px #f28d0b;
}
ul.classGridList li.full p.status {
  color: #d72a2c;
  border-bottom: solid 2px #d72a2c;
}
ul.classGridList li h3 {
  font-size: 93%;
  line-height: 1.4;
  margin: 0 15px;
}
ul.classGridList li p.more {
  font-family: "Lato", sans-serif;
  font-size: 11px;
  line-height: 31px;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  margin-bottom: 0;
  text-align: center;
  letter-spacing: 1px;
  color: #b4b4b4;
  background: #f2f2f2 url(../../img/common/arw_min_gry_r.png) 17px center no-repeat;
}
ul.classGridList li a:hover {
  opacity: 1;
  border: solid 1px #00b458;
  filter: alpha(opacity=100);
  -moz-opacity: 1;
}
ul.classGridList li a:hover p.more {
  color: #fff;
  background: #00b458 url(../../img/common/arw_min_gry_r.png) 17px center no-repeat;
}

/*
ul.classGridList li a:hover p.pic,
ul.classGridList li a:hover h3,
ul.classGridList li a:hover p.status {
	filter: alpha(opacity=70);
	-moz-opacity: 0.7;
	opacity: 0.7;
}
*/
@media (max-width: 47.99em) {
  ul.classGridList {
    margin-right: 0;
  }
  ul.classGridList li {
    display: block;
    float: none;
    width: auto;
    height: auto !important;
    margin: 0 -10px;
    padding: 5px 10px;
    border-bottom: solid 1px #efefef;
  }
  ul.classGridList li:last-child {
    border-bottom: none;
  }
  ul.classGridList li a {
    position: relative;
    overflow: hidden;
    height: auto !important;
    padding: 5px 25px 5px 0;
    text-decoration: none;
    border: none;
    background: url(../../img/common/bdr_15px_eee.png) right top repeat-y;
  }
  ul.classGridList li a:hover {
    border: none;
  }
  ul.classGridList li a::after {
    position: absolute;
    top: 47%;
    right: 5px;
    display: block;
    width: 5px;
    height: 7px;
    content: "";
    background: url(../../img/common/sp/arw_min_gry_r.png) left top no-repeat;
    background-size: 5px;
  }
  ul.classGridList li p.date {
    font-weight: bold;
    position: relative;
    top: 0;
    left: 0;
    width: auto;
    height: auto;
    margin-bottom: 5px;
    text-align: left;
    letter-spacing: 1px;
    color: #333;
    background: none;
  }
  ul.classGridList li.ended p.date {
    background: none;
  }
  ul.classGridList li p.date span {
    display: inline;
  }
  ul.classGridList li p.date span.year {
    font-size: inherit;
    margin-top: 0;
    letter-spacing: 0;
  }
  ul.classGridList li p.date span.year span {
    display: inline;
  }
  ul.classGridList li p.date span.day {
    font-size: inherit;
  }
  ul.classGridList li p.date span.week {
    font-size: 86%;
    color: inherit;
  }
  ul.classGridList li p.dateMulti {
    left: auto;
    width: auto;
    padding: 0;
    background: none;
  }
  ul.classGridList li.ended p.dateMulti {
    background: none;
  }
  ul.classGridList li p.dateMulti span.fromto {
    display: inline;
  }
  ul.classGridList li p.dateMulti span.start, ul.classGridList li p.dateMulti span.end {
    display: inline;
    float: none;
    width: auto;
    text-align: left;
  }
  ul.classGridList li p.pic {
    display: inline;
    float: left;
    width: 38%;
    height: auto;
    margin: 0 4% 0 0;
    border-bottom: none;
  }
  ul.classGridList li p.pic img {
    height: auto;
    margin-top: 0 !important;
  }
  ul.classGridList li p.pic img.noimage {
    width: 98%;
    border: solid 1px #dfdfdf;
  }
  ul.classGridList li p.status {
    margin: 0 0 8px 0;
  }
  ul.classGridList li h3 {
    overflow: hidden;
    height: auto !important;
    margin: 0;
  }
  ul.classGridList li p.more {
    display: none;
  }
}
/* screen and (max-width:640px) */
.classReportSection {
  margin-top: 40px;
}
.classReportSection h2 {
  font-size: 108%;
  position: relative;
  display: inline-block;
  margin-bottom: 25px;
  padding-bottom: 8px;
  color: #00b458;
}
.classReportSection h2::after {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 98%;
  height: 3px;
  content: "";
  background: #00b458;
}

.classReportGridList .badge {
  font-weight: bold;
  position: absolute;
  z-index: 200;
  top: -9px;
  left: 184px;
  width: 58px;
  height: 58px;
  color: #fff;
  background: url(../../img/common/bg_date.png) 0 0 no-repeat;
}
.classReportGridList .badge .badgeInner {
  font-size: 86%;
  position: absolute;
  top: 50%;
  left: 0;
  display: block;
  width: 100%;
  margin-top: -1em;
  text-align: center;
}

@media (max-width: 47.99em) {
  .classReportSection {
    margin-top: 20px;
  }
  .classReportSection h2 {
    font-size: 100%;
    margin-bottom: 10px;
  }
  .classReportGridList .badge {
    font-weight: normal;
    position: relative;
    top: auto;
    right: auto;
    left: auto;
    display: inline-block;
    width: auto;
    height: auto;
    margin: 0;
    margin-bottom: 8px;
    padding: 0;
    background: #00b458;
  }
  .classReportGridList .badge .badgeInner {
    font-size: 79%;
    position: relative;
    top: auto;
    right: auto;
    left: auto;
    display: inline-block;
    width: auto;
    height: auto;
    margin: 0;
    padding: 0;
    padding: 2px 5px;
  }
  .classReportGridList .badge br {
    display: none;
  }
}
/* screen and (max-width:640px) */
/* -----------------------------------
	activityGridList
----------------------------------- */
.activityGridList {
  display: flex;
  flex-wrap: wrap;
  position: relative;
  justify-content: space-between;
}
.activityGridList li {
  line-height: 1.3;
  display: block;
  width: 100%;
  margin-bottom: 30px;
}
@media (min-width: 48em) {
  .activityGridList li {
    width: calc(33.3333333333% - 1rem);
  }
}
@media (min-width: 62em) {
  .activityGridList li {
    width: calc(25% - 1rem);
  }
}
.activityGridList li a {
  position: relative;
  overflow: hidden;
  padding: 5px 25px 5px 0;
  text-decoration: none;
  border: none;
  background: url(../../img/common/bdr_15px_eee.png) right top repeat-y;
}
@media (min-width: 48em) {
  .activityGridList li a {
    padding: 0;
    background: none;
  }
}
.activityGridList li a::after {
  position: absolute;
  top: 47%;
  right: 5px;
  display: block;
  width: 5px;
  height: 7px;
  content: "";
  background: url(../../img/common/sp/arw_min_gry_r.png) left top no-repeat;
  background-size: 5px;
}
@media (min-width: 48em) {
  .activityGridList li a::after {
    display: none;
  }
}
.activityGridList li p.pic {
  position: relative;
  display: block;
  float: left;
  overflow: hidden;
  width: 80px;
  margin-right: 1em;
  margin-bottom: 10px;
  border: solid 1px #dfdfdf;
}
@media (min-width: 48em) {
  .activityGridList li p.pic {
    float: none;
    width: 100%;
  }
}
.activityGridList li p.pic::before {
  position: relative;
  display: block;
  padding-top: 75%;
  content: "";
}
.activityGridList li p.pic img {
  position: absolute;
  top: 0;
  left: 0;
  object-fit: cover;
  width: 100%;
  height: 100%;
  margin-top: 0 !important;
  object-position: center;
}
.activityGridList li h3 {
  font-size: 86%;
  line-height: 1.5;
  margin-bottom: 6px;
  text-decoration: underline;
}
.activityGridList li p.project {
  font-size: 79%;
  overflow: hidden;
  color: #02964a;
}
.activityGridList li p.desc {
  font-size: 79%;
  overflow: hidden;
  color: #999;
}
.activityGridList li p.univ_info {
  font-size: 86%;
  font-weight: bold;
  margin-top: 5px;
  vertical-align: middle;
}
.activityGridList li p.univ_info img {
  width: 30px;
  margin-right: 8px;
  vertical-align: middle;
}

.sisterSchoolList li a {
  text-decoration: underline;
}
.sisterSchoolList li .desc a {
  text-decoration: none;
}

/* sisterSchoolList */
/* screen and (max-width:640px) */
/* -----------------------------------
	clubGridList
----------------------------------- */
.clubGridList {
  position: relative;
  margin-right: -25px;
}
.clubGridList li {
  line-height: 1.2;
  position: relative;
  display: inline;
  float: left;
  width: 228px;
  margin: 0 25px 30px 0;
  padding: 15px 0 30px 0;
  border: solid 1px #dfdfdf;
}
.clubGridList li.ended {
  padding-bottom: 0;
}
.clubGridList li p.pic {
  position: relative;
  z-index: 100;
  overflow: hidden;
  height: 155px;
  margin: -15px 0 10px 0;
  border-bottom: solid 1px #dfdfdf;
}
.clubGridList li p.pic img {
  width: 100%;
}
.clubGridList li h3 {
  font-size: 108%;
  line-height: 1.4;
  margin: 0 15px 5px;
}
.clubGridList li p.desc {
  font-size: 86%;
  line-height: 1.7;
  margin: 0 15px 15px;
}
.clubGridList li p.desc a {
  text-decoration: underline;
}
.clubGridList li p.desc a:hover {
  text-decoration: none;
  border: none;
}
.clubGridList li dl.blog {
  font-size: 79%;
  margin: 0 15px 15px 15px;
}
.clubGridList li dl.blog dt {
  line-height: 1.7;
  display: inline-block;
  margin-bottom: 5px;
  padding: 0 1.2em;
  color: #fff;
  border-bottom: none;
  background-color: #00b458;
}
.clubGridList li dl.blog dd {
  line-height: 1.8;
}
.clubGridList li dl.blog dd a {
  text-decoration: underline;
}
.clubGridList li dl.blog dd a:hover {
  text-decoration: none;
  border: none;
}
.clubGridList li p.more {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 228px;
}
.clubGridList li p.more a {
  font-family: "Lato", sans-serif;
  font-size: 11px;
  line-height: 31px;
  display: block;
  width: 228px;
  text-align: center;
  text-decoration: none;
  letter-spacing: 1px;
  color: #b4b4b4;
  background: #f2f2f2 url(../../img/common/arw_min_gry_r.png) 17px center no-repeat;
}
.clubGridList li p.more a:hover {
  opacity: 1;
  color: #fff;
  background: #00b458 url(../../img/common/arw_min_gry_r.png) 17px center no-repeat;
  filter: alpha(opacity=100);
  -moz-opacity: 1;
}
.clubGridList li p.clubStatus {
  position: absolute;
  z-index: 200;
  top: -24px;
  left: 189px;
  width: 58px;
  height: 58px;
  text-align: center;
  color: #fff;
  background: url(../../img/common/bg_date.png) 0 0 no-repeat;
}
.clubGridList li.ended p.clubStatus {
  background: url(../../img/common/bg_date.png) -58px 0 no-repeat;
}
.clubGridList li.ended p.clubStatus span {
  font-size: 90%;
  line-height: 58px;
}

@media (max-width: 47.99em) {
  ul.clubGridList {
    margin-right: 0;
  }
  ul.clubGridList li {
    display: block;
    float: none;
    overflow: hidden;
    width: auto;
    height: auto !important;
    margin: 0 -10px;
    padding: 10px 10px;
    border: none;
    border-bottom: solid 1px #efefef;
  }
  ul.clubGridList li.ended {
    padding: 10px 10px;
  }
  ul.clubGridList li p.pic {
    display: inline;
    float: left;
    width: 38%;
    height: auto;
    margin: 0 4% 0 0;
    border-bottom: none;
  }
  ul.clubGridList li h3 {
    font-size: 100%;
    overflow: hidden;
    height: auto !important;
    margin: 0 0 5px 0;
  }
  ul.clubGridList li p.desc {
    overflow: hidden;
    height: auto !important;
    margin: 0;
  }
  /*	ul.clubGridList li .inner {
  		position: relative;
  		overflow: hidden;
  		padding: 5px 25px 5px 0;
  		border: none;
  		text-decoration: none;
  		background: url(../../img/common/bdr_15px_eee.png) right top repeat-y;
  	}
  	ul.clubGridList li .inner:after {
  		content: '';
  		position: absolute;
  		right: 5px;
  		top: 47%;
  		display: block;
  		width: 5px;
  		height: 7px;
  		background: url(../../img/common/sp/arw_min_gry_r.png) left top no-repeat;
  		background-size: 5px;
  	}
  */
  .clubGridList li dl.blog {
    clear: both;
    overflow: hidden;
    margin: 0;
    padding-top: 10px;
  }
  .clubGridList li p.more {
    display: none;
  }
  .clubGridList li p.clubStatus {
    font-size: 72%;
    line-height: 1.8;
    position: relative;
    top: auto;
    left: auto;
    display: inline-block;
    width: auto;
    height: auto;
    margin-bottom: 5px;
    padding: 0 1em;
    text-align: center;
    color: #fff;
    background: #00b458;
  }
  .clubGridList li.ended p.clubStatus {
    background: #666;
  }
  .clubGridList li.ended p.clubStatus span {
    font-size: inherit;
    line-height: normal;
  }
  /*
  .clubGridList li p.more {
  	position: relative;
  	bottom: 0;
  	left: 0;
  	overflow: hidden;
  	float: right;
  	width: 6em;
  	margin-top: 5px;
  }
  .clubGridList li p.more a {
  	display: block;
  	width: auto;
  	padding-left: 9px;
  	background: #f2f2f2 url(../../img/common/arw_min_gry_r.png) 17px center no-repeat;
  	color: #b4b4b4;
  	font-family: 'Lato', sans-serif;
  	font-size: 11px;
  	line-height: 26px;
  	text-align: center;
  	text-decoration: none;
  	letter-spacing: 1px;
  }
  */
}
/* screen and (max-width:640px) */
/* -----------------------------------
	Search Result
----------------------------------- */
.search_result {
  overflow: hidden;
  margin-bottom: 30px;
}
.search_result .result_text {
  float: left;
  color: #ec7832;
}
.search_result .link_not_search {
  float: right;
  padding-left: 15px;
  background: url("../../img/common/arw_basic_grn_r.png") no-repeat scroll 0.1em center rgba(0, 0, 0, 0);
}

/* -----------------------------------
	classList
----------------------------------- */
ul.classList {
  overflow: hidden;
  margin-top: -20px;
}
ul.classList li {
  margin-top: -3px;
  padding: 30px 0;
  border-top: solid 3px #efefef;
}
ul.classList li a {
  text-decoration: none;
}
ul.classList li .data {
  display: inline;
  float: left;
  width: 150px;
  margin-right: 25px;
}
ul.classList li .pic {
  display: inline;
  float: right;
  overflow: hidden;
  width: 180px;
  height: expression(document.body.clientWidth > 180? "180px": "auto");
  max-height: 180px;
  margin-left: 25px;
}
ul.classList li .pic img {
  width: 100%;
}
ul.classList li .pic img.noimage {
  width: 98%;
  border: solid 1px #dfdfdf;
}
ul.classList li .text {
  overflow: hidden;
}
ul.classList li .data .date {
  font-family: "Lato", sans-serif;
  line-height: 1.2;
  width: 150px;
  height: 110px;
  padding-top: 40px;
  text-align: center;
  letter-spacing: 1px;
  color: #fff;
  background: #00b458;
}
ul.classList li .data .two_date {
  font-family: "Lato", sans-serif;
  line-height: 1.2;
  width: 150px;
  height: 110px;
  padding: 20px 0;
  letter-spacing: 1px;
  color: #fff;
  background: #00b458;
}
ul.classList li .data .date p.day {
  font-size: 37px;
}
ul.classList li .data .two_date p.day {
  font-size: 30px;
}
ul.classList li .data .date p.day span,
ul.classList li .data .two_date p.day span {
  font-size: 14px;
  margin-left: 3px;
  color: #e4ff00;
}
ul.classList li .data .date p.time {
  font-size: 20px;
}
ul.classList li .data .two_date p.time {
  font-size: 18px;
}
ul.classList li .data .status {
  font-size: 72%;
  font-weight: bold;
  line-height: 2.2em;
  display: block;
  height: 2.1em;
  margin-top: 6px;
  padding: 0;
  text-align: center;
  letter-spacing: 1px;
  border: solid 2px #00b458;
  background: #fff;
}
ul.classList li .data .status.entry {
  color: #fff;
  border: solid 2px #f60;
  background: #f60;
}
ul.classList li .data .status.elected, ul.classList li .data .status.presence {
  color: #fff;
  border: solid 2px #ec0515;
  background: #ec0515;
}
ul.classList li .data .status.rejected, ul.classList li .data .status.absence {
  color: #ec0515;
  border: solid 2px #ec0515;
}
ul.classList li .data .status.ended {
  color: #f60;
  border: solid 2px #f60;
}
ul.classList li .data .action a {
  font-size: 72%;
  font-weight: bold;
  line-height: 2.2em;
  display: block;
  height: auto;
  margin-top: 6px;
  padding: 0 0 0 10px;
  text-align: center;
  text-decoration: none;
  letter-spacing: 1px;
  color: #00b458;
  border: solid 2px #00b458;
  background: #fff url(../../img/common/arw_basic_grn_r.png) 7px center no-repeat;
}
ul.classList li .text p.label {
  margin-bottom: 5px;
}
ul.classList li .text p.label span.class {
  font-size: 93%;
  color: #5e5e5e;
}
ul.classList li .text p.label span.status {
  position: relative;
  top: -3px;
  margin-left: 10px;
}
ul.classList li.closed .text p.label .status {
  color: #777;
  border-bottom: 2px solid #777;
}
ul.classList li.just_rest .text p.label .status {
  color: #f08e09;
  border-bottom: 2px solid #f08e09;
}
ul.classList li.full .text p.label .status {
  color: #d72a2c;
  border-bottom: 2px solid #d72a2c;
}
ul.classList li .text h2 {
  font-size: 143%;
  line-height: 1.5;
  text-decoration: underline;
}
ul.classList li a:hover .text h2 {
  text-decoration: none;
}
ul.classList li .text p.desc {
  font-size: 86%;
  margin-top: 20px;
}

/* ul.classList li .data .date p,
ul.classList li .data .two_date p {
	margin-left: 20px;
} */
.pastSection ul.classList {
  border-bottom: solid 3px #efefef;
}
.pastSection ul.classList li .data .date,
.pastSection ul.classList li .data .two_date {
  background: #8b8b8b;
}

@media (max-width: 47.99em) {
  ul.classList {
    overflow: hidden;
    margin-top: 0;
  }
  ul.classList li {
    margin-top: 0;
    padding: 10px 0;
    border-top: none;
  }
  ul.classList li div {
    text-align: left;
  }
  ul.classList li .data {
    display: block;
    float: none;
    overflow: hidden;
    width: auto;
    margin: 0 0 10px 0;
  }
  ul.classList li .pic {
    display: inline;
    float: left;
    clear: both;
    width: 28%;
    height: auto;
    margin: 0 4% 10px 0;
  }
  ul.classList li .text {
    overflow: visible;
  }
  ul.classList li .data .date {
    width: auto;
    height: auto;
    padding: 3px 10px;
    text-align: left;
  }
  ul.classList li .data .date p {
    display: inline;
    margin: 0 10px 0 0;
  }
  ul.classList li .data .date p.day {
    font-size: 129%;
  }
  ul.classList li .data .date p.day span {
    font-size: 14px;
    margin-left: 3px;
    color: #e4ff00;
  }
  ul.classList li .data .date p.time {
    font-size: 115%;
  }
  ul.classList li .data .status {
    line-height: 2em;
    float: left;
    width: 46%;
    height: 1.9em;
    letter-spacing: normal;
  }
  ul.classList li .data .action a {
    line-height: 2em;
    float: right;
    width: 46%;
    height: 1.9em;
    letter-spacing: normal;
  }
  ul.classList li .text h2 {
    font-size: 115%;
    overflow: hidden;
  }
  ul.classList li .text p.desc {
    font-size: 86%;
    margin-top: 10px;
  }
}
/* screen and (max-width:640px) */
/* -----------------------------------
	shopList
----------------------------------- */
ul.shopList li {
  overflow: hidden;
  padding: 22px 0;
  border-top: 3px solid #efefef;
}
ul.shopList li .pic {
  float: left;
  overflow: hidden;
  width: 150px;
  height: 150px;
}
ul.shopList li .pic img {
  width: 100%;
}
ul.shopList li .text {
  line-height: 1.5;
  float: right;
  overflow: hidden;
  width: 565px;
  padding-top: 12px;
}
ul.shopList li .text h2 {
  font-size: 158%;
  font-weight: normal;
  width: 100%;
  padding-bottom: 20px;
}
ul.shopList li h2 a {
  text-decoration: underline;
  color: #222;
}
ul.shopList li h2 a:hover, ul.shopList li h2 a:active {
  text-decoration: none;
}
ul.shopList li .text .address {
  font-size: 93%;
  float: left;
  width: 355px;
}
ul.shopList li .text .address span.arwLink {
  display: block;
  margin-top: 10px;
}
ul.shopList li .text .address span.arwLink a {
  font-size: 100%;
  font-weight: normal;
  text-decoration: underline;
  background-position: left center;
}
ul.shopList li .text .address span.arwLink a:hover {
  text-decoration: none;
}
ul.shopList li .text .service {
  font-size: 93%;
  float: right;
  width: 188px;
  padding-left: 22px;
}
ul.shopList li .text .service dt {
  font-weight: bold;
  padding-bottom: 4px;
  color: #00a350;
}
ul.shopGridList {
  display: flex;
  flex-wrap: wrap;
  margin-right: -17px;
}
ul.shopGridList li {
  width: 100%;
  margin: 0 0 1rem;
  padding: 0 0.5rem 0.5rem;
}
@media (min-width: 62em) {
  ul.shopGridList li {
    flex: 0 0 33.3333333333%;
    width: 100%;
    max-width: 33.3333333333%;
  }
}
ul.shopGridList li .pic {
  float: left;
  width: 100px;
  margin-right: 15px;
}
ul.shopGridList li .pic img {
  width: 100%;
}
ul.shopGridList li .text {
  overflow: hidden;
}
ul.shopGridList li .text h2 {
  font-size: 93%;
}
ul.shopGridList li h2 a {
  color: #222;
}
ul.shopGridList li h2 a:hover, ul.shopGridList li h2 a:active {
  text-decoration: none;
}
ul.shopGridList li .text .service {
  margin-top: 8px;
}
ul.shopGridList li .text .service dt {
  font-size: 86%;
  font-weight: bold;
  margin-bottom: 2px;
  color: #00a350;
}
ul.shopGridList li .text .service dd {
  font-size: 79%;
  line-height: 1.4;
}
ul.formFlow {
  line-height: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}
ul.formFlow li {
  display: inline-block;
  padding: 0 71px 0 0;
  background: url(../../img/common/arw_flow_next.png) no-repeat 215px center;
}
ul.formFlow li p {
  font-weight: bold;
  line-height: 58px;
  display: inline-block;
  width: 189px;
  margin-bottom: 0;
  text-align: center;
  vertical-align: middle;
  color: #b6b7b6;
  border: 5px solid #b6b7b6;
}

/* screen and (max-width:640px) */
/* -----------------------------------
	shopGridList
----------------------------------- */
@media (max-width: 47.99em) {
  ul.shopGridList {
    margin-right: 0;
  }
  ul.shopGridList li {
    position: relative;
    float: none;
    width: auto;
    height: auto !important;
    margin: 0 -10px;
    padding: 10px;
    border-top: 1px solid #efefef;
  }
  ul.shopGridList li:first-child {
    border-top: none;
  }
  ul.shopGridList li .pic {
    float: left;
    width: 28%;
    margin-right: 4%;
  }
  ul.shopGridList li .pic img {
    width: 100%;
  }
  ul.shopGridList li .text {
    overflow: hidden;
  }
  ul.shopGridList li .text h2 {
    font-size: 100%;
  }
}
/* screen and (max-width:640px) */
/* -----------------------------------
	formFlow
----------------------------------- */
.pcScreen ul.formFlow li p {
  height: auto !important;
}

ul.formFlow li.on p {
  color: #00b458;
  border: 5px solid #00b458;
}
ul.formFlow li.passed {
  background: url(../../img/common/arw_flow_passed.png) no-repeat 223px center;
}
ul.formFlow li.passed p {
  color: #dfdfdf;
  border: 5px solid #dfdfdf;
}
ul.formFlow li.right {
  padding: 0;
}

dl.mailCaution {
  padding: 16px;
  letter-spacing: 0.1em;
  border: 2px solid #00b458;
}
dl.mailCaution dt {
  padding: 0 0 1em;
  color: #09ad59;
}
dl.mailCaution dd dl.checkList {
  font-size: 86%;
  padding: 0 0 0.8em;
}
dl.mailCaution dd dl.checkList dt {
  font-weight: bold;
  padding: 0 0 2px 16px;
  color: #222;
  background: url(../../img/common/icn_check_blk.png) no-repeat left 4px;
}

.ie6 dl.mailCaution dd dl.checkList dt {
  zoom: 1;
}

dl.mailCaution dd dl.checkList dd {
  padding: 0 0 10px 16px;
  letter-spacing: normal;
  color: #222;
}

@media (max-width: 47.99em) {
  ul.formFlow {
    width: auto;
    margin: 15px 0;
    padding-left: 0;
  }
  ul.formFlow li {
    font-size: 10px;
    width: 33.3333333333%;
    padding: 0;
  }
  ul.formFlow li p {
    line-height: normal;
    display: block;
    width: auto;
    padding: 5px 5px;
    border: 2px solid #b6b7b6;
    border-left: none;
  }
  ul.formFlow li.on p {
    border: 2px solid #00b458;
  }
  ul.formFlow li.passed p {
    border: 2px solid #dfdfdf;
    border-right: none;
  }
  dl.mailCaution {
    padding: 10px;
    letter-spacing: normal;
    border: 2px solid #00b458;
  }
}
/* screen and (max-width:640px) */
/* -----------------------------------
	blogSection
----------------------------------- */
.blogSection h2 {
  font-size: 179%;
  line-height: 1.3;
  border-top: 3px solid #efefef;
  border-bottom: 3px solid #efefef;
}
.blogSection .blogPost {
  font-size: 79%;
  line-height: 1;
  color: #333;
}
.blogSection .blogPost span.category {
  margin-right: 1em;
}
.blogSection .blogBody {
  padding-bottom: 1em;
}
.blogSection .blogBody p,
.blogSection .blogBody img.pic {
  margin: 1em 0;
}

.blogTopNav {
  text-align: center;
}
.blogTopNav a {
  font-size: 14px;
  font-weight: bold;
  text-decoration: none;
  color: #00b458;
}

/* -----------------------------------
	basicSection
----------------------------------- */
.basicSection {
  margin-bottom: 40px;
}
.basicSection p {
  margin-bottom: 1em;
}
.basicSection p.button {
  margin-top: 30px;
}

@media (max-width: 47.99em) {
  .basicSection {
    margin-bottom: 20px;
    padding: 0 3px;
  }
  .basicSection .basicTitle {
    margin-right: -3px;
    margin-left: -3px;
  }
  .basicSection p,
  .basicSection ul,
  .basicSection ol,
  .basicSection dl {
    font-size: 86%;
  }
  .basicSection p.button {
    margin-top: 15px;
  }
  .basicSection .arwLink a {
    font-size: 100%;
  }
}
/* screen and (max-width:640px) */
/* -----------------------------------
	leadSection
----------------------------------- */
.leadSection {
  line-height: 1.5;
  overflow: hidden;
  margin-bottom: 19px;
  padding-left: 20px;
  border-left: solid 10px #00b458;
}
.leadSection h1 {
  font-size: 1.5rem;
  display: block;
  margin-bottom: 0;
  letter-spacing: 1px;
}
.leadSection h1 span.category {
  font-size: 60%;
  font-weight: bold;
  display: block;
  margin-bottom: 5px;
  color: #5e5e5e;
}
.leadSection h1 span.small {
  font-size: 65%;
}
.leadSection p.pic {
  display: inline;
  float: right;
  width: 200px;
  margin-left: 20px;
}
.leadSection p.pic img {
  width: 100%;
}
.leadSection p.pic img.noimage {
  width: 98%;
  border: solid 1px #dfdfdf;
}
.leadSection p.desc {
  font-size: 93%;
  font-weight: bold;
  clear: left;
  padding: 10px 0 5px 0;
}
.leadSection p.desc span {
  font-size: 75%;
}
.leadSection ul.socialButtons {
  margin-top: 8px;
}

@media (max-width: 47.99em) {
  .leadSection {
    margin-bottom: 8px;
    padding-left: 0;
    border-left: none;
  }
  .leadSection h1 {
    font-size: 129%;
    float: none;
    padding-left: 10px;
    letter-spacing: normal;
    border-left: solid 5px #00b458;
  }
  .leadSection h1 span.small {
    font-size: 65%;
  }
  .leadSection p.pic {
    display: block;
    float: none;
    width: auto;
    margin: 10px 20%;
  }
  .leadSection p.desc {
    font-size: 86%;
    margin-top: 10px;
    padding: 0;
  }
  .leadSection p.desc span {
    font-size: 84%;
  }
  .leadSection ul.socialButtons {
    margin-top: 5px;
  }
}
/* screen and (max-width:640px) */
/* -----------------------------------
	contactSection
----------------------------------- */
.contactSection {
  margin: 2em 0 0;
  padding: 28px;
  border: 1px solid #ddd;
}
.contactSection .contact .name {
  font-size: 108%;
  margin-right: 1.2em;
  letter-spacing: 0.15em;
  color: #02964a;
}
.contactSection .contact .data {
  font-family: "Lato", sans-serif;
  font-size: 89%;
  letter-spacing: 0.03em;
}
.contactSection .contact address {
  font-size: 86%;
  letter-spacing: 0.15em;
}

/* screen and (max-width:640px) */
/* ============================================================

	style info : トップのスタイルを記述

============================================================ */
/* screen and (max-width:640px) */
/* ============================================================

	style info : 授業のスタイルを記述

============================================================ */
/* -----------------------------------
	list
----------------------------------- */
#class .pastSection {
  margin-top: 30px;
}
#class .detail .leadSection h1 {
  width: 520px;
}
#class .detail .leadSection p.desc {
  line-height: 1.7;
  margin-top: 8px;
}
#class .detail .noBorder {
  padding-top: 20px;
  padding-left: 0;
  border-top: 3px solid #efefef;
  border-left: none;
}
#class .detail .noBorder h1 {
  font-size: 179%;
}
#class .entrySection {
  margin-top: 10px;
  padding: 25px;
  background: #f2f2f2;
}
#class .entrySection em {
  font-weight: normal;
  font-style: italic;
}
#class .entrySection em strong {
  font-weight: bold;
  font-style: italic;
}
#class .entrySection strong em {
  font-weight: bold;
  font-style: italic;
}
#class .entrySection .dataColumn {
  display: inline;
  float: left;
  width: 495px;
}
#class .entrySection .dataColumn h2 {
  font-size: 115%;
  font-weight: bold;
  margin-bottom: 15px;
}
#class .entrySection .dataColumn p {
  font-size: 79%;
}
#class .entrySection .dataColumn .txt_alert {
  color: #e00;
}
#class .entrySection .dataColumn dl.cautionList {
  font-size: 79%;
}
#class .entrySection .dataColumn dl.cautionList dd {
  margin-bottom: 0;
}
#class .entrySection .actionColumn {
  display: inline;
  float: right;
  width: 175px;
  text-align: center;
}
#class .entrySection.ended .dataColumn p,
#class .entrySection.ended .dataColumn dl.cautionList {
  margin-right: -180px;
}
#class .entrySection.not_reception .dataColumn p {
  margin-right: -180px;
}
#class .entrySection.closed .status {
  color: #777;
  border-bottom: 2px solid #777;
}
#class .open .actionColumn,
#class .just_rest .actionColumn {
  text-align: left;
}
#class .not_reception .actionColumn {
  text-align: center;
}
#class .just_rest .status {
  color: #f08e09;
  border-bottom: 2px solid #f08e09;
}
#class .entrySection.full .status {
  color: #d72a2c;
  border-bottom: 2px solid #d72a2c;
}
#class .entrySection .actionColumn p.desc {
  font-size: 86%;
  font-weight: bold;
  line-height: 1.3;
  margin-top: 10px;
}
#class .entrySection .reportColumn {
  margin-bottom: 25px;
}
#class .entrySection .reportColumn h2 {
  font-size: 129%;
  margin: -7px 0 5px 0;
  padding: 7px 0 5px 36px;
  color: #00b458;
  background: url(../../img/common/pct_report.png) left center no-repeat;
}
#class .entrySection .reportColumn .pic {
  overflow: hidden;
  margin-right: -7px;
}
#class .entrySection .reportColumn .pic li {
  float: left;
  overflow: hidden;
  width: 80px;
  height: 80px;
  margin-right: 7px;
  background: center center no-repeat;
  background-size: cover;
}
#class .outlineSection,
#class .photoSection {
  overflow: hidden;
  margin: 40px 0;
}
#class .outlineSection em {
  font-weight: normal;
  font-style: italic;
}
#class .outlineSection em strong {
  font-weight: bold;
  font-style: italic;
}
#class .outlineSection strong em {
  font-weight: bold;
  font-style: italic;
}
#class .outlineSection p {
  margin: 25px 0;
}
#class .photoSection p {
  margin: 25px 0;
}
#class .outlineSection p.pic,
#class .photoSection p.pic {
  text-align: center;
}
#class .outlineSection .moreLink p.pic,
#class .photoSection .moreLink p.pic {
  display: inline-block;
  width: 1em;
  text-align: left;
}

/* -----------------------------------
	detail
----------------------------------- */
@media (max-width: 47.99em) {
  #class .entrySection.ended .dataColumn p,
  #class .entrySection.ended .dataColumn dl.cautionList {
    margin-right: 0;
  }
  #class .entrySection.not_reception .dataColumn p {
    margin-right: 0;
  }
}
/* screen and (max-width:640px) */
.ieOld #class .outlineSection .moreLink p.pic,
.ieOld #class .photoSection .moreLink p.pic {
  display: inline;
  zoom: 1;
}

#class .outlineSection h3 {
  font-size: 120%;
  color: #008000;
}
#class .photoSection dl {
  line-height: 1.6;
}
#class .photoSection dl dt {
  width: 3.2em;
}
#class .photoSection dl dd {
  margin: -1.6em 0 1.5em 3.2em;
}
#class .professorSection .professor {
  padding: 40px 0;
  border-top: solid 3px #efefef;
}
#class .professorSection .professor p {
  overflow: hidden;
  margin-top: 10px;
}
#class .professorSection .professor p.pic {
  display: inline;
  float: right;
  width: 190px;
  margin: 0 0 0 35px;
  text-align: right;
}
#class .professorSection .professor p.pic img {
  width: 100%;
}
#class .professorSection .professor h2 {
  font-size: 158%;
}
#class .professorSection .professor h2 span {
  font-size: 68%;
  margin-left: 1em;
}
#class .classroomSection {
  padding-top: 25px;
  border-top: solid 3px #efefef;
}
#class .classroomSection h2 {
  font-size: 143%;
  margin-bottom: 30px;
  padding: 0 0 0 48px;
  color: #00b458;
  background: url(../../img/common/pct_classroom.png) left center no-repeat;
}
#class .classroomSection p {
  overflow: hidden;
}
#class .classroomSection p.pic {
  display: inline;
  float: right;
  width: 190px;
  margin: 0 0 35px 35px;
  text-align: right;
}
#class .classroomSection p.pic img {
  width: 100%;
}
#class .classroomSection .contact {
  overflow: hidden;
  margin-top: 30px;
}
#class .classroomSection .contact h3 {
  font-size: 86%;
  font-weight: bold;
  display: inline-block;
  margin-bottom: 15px;
  padding: 6px 15px;
  color: #fff;
  background: #00b458;
}
#class .classroomSection .contact dl.cautionList {
  font-size: 79%;
  margin-top: 10px;
}
#class .classroomSection .contact p.desc {
  font-size: 86%;
  margin-top: 10px;
}

@media (max-width: 47.99em) {
  #class .detail .leadSection h1 {
    font-size: 129%;
    width: auto;
  }
  #class .detail .noBorder {
    padding-top: 10px;
    border-top: 1px solid #efefef;
  }
  #class .detail .noBorder h1 {
    padding-left: 0;
    word-break: break-all;
    border-left: none;
  }
  #class .detail .leadSection p.desc {
    margin-top: 10px;
  }
  #class .entrySection {
    padding: 15px;
    background: #f2f2f2;
  }
  #class .entrySection .dataColumn {
    display: block;
    float: none;
    width: auto;
  }
  #class .entrySection .dataColumn h2 {
    font-size: 100%;
    line-height: 1.6;
  }
  #class .entrySection .dataColumn h2 span {
    display: inline-block;
  }
  #class .entrySection .dataColumn p {
    margin-bottom: 10px;
  }
  #class .entrySection .dataColumn p span {
    display: inline-block;
  }
  #class .entrySection .dataColumn dl.cautionList dd {
    margin-bottom: 10px;
  }
  #class .entrySection.ended .dataColumn p,
  #class .entrySection.ended .dataColumn dl.cautionList {
    margin-right: 0;
  }
  #class .entrySection .actionColumn {
    display: block;
    float: none;
    width: auto;
    padding-top: 10px;
    border-top: dotted 1px #999;
  }
  #class .entrySection .reportColumn {
    margin-bottom: 20px;
  }
  #class .entrySection .reportColumn .button a,
  #class .entrySection .reportColumn h2 {
    font-size: 100%;
  }
  #class .entrySection .reportColumn .pic {
    margin-right: -2%;
  }
  #class .entrySection .reportColumn .pic li {
    width: 23%;
    height: 60px;
    margin-right: 2%;
  }
  #class .entrySection .reportColumn .pic li:nth-child(n+5) {
    display: none;
  }
  #class .outlineSection,
  #class .photoSection {
    margin: 20px 0;
  }
  #class .outlineSection p {
    font-size: 86%;
    margin: 1em 0;
  }
  #class .photoSection p {
    font-size: 86%;
    margin: 1em 0;
  }
  #class .photoSection dl {
    font-size: 86%;
  }
  #class .photoSection dl dd {
    margin-bottom: 1em;
  }
  #class .professorSection .professor {
    padding: 15px 0;
    border-top: solid 2px #efefef;
  }
  #class .professorSection .professor p {
    font-size: 86%;
    text-align: justify;
  }
  #class .professorSection .professor p.pic {
    display: block;
    float: none;
    width: auto;
    margin: 10px 25%;
    text-align: center;
  }
  #class .professorSection .professor h2 {
    font-size: 129%;
  }
  #class .professorSection .professor h2 span {
    font-size: 68%;
    display: block;
    margin: 0;
  }
  #class .classroomSection {
    padding-top: 15px;
    border-top: solid 2px #efefef;
  }
  #class .classroomSection h2 {
    font-size: 100%;
    margin-bottom: 10px;
  }
  #class .classroomSection p {
    font-size: 86%;
  }
  #class .classroomSection p.pic {
    display: block;
    float: none;
    width: auto;
    margin: 10px 25%;
    text-align: center;
  }
  #class .classroomSection .contact h3 {
    margin-bottom: 10px;
  }
}
/* screen and (max-width:640px) */
/* ============================================================

	style info : 先生のスタイルを記述

============================================================ */
/* -----------------------------------
	list
----------------------------------- */
#professor .navTop {
  margin-bottom: 13px;
}
#professor .professorListSection {
  overflow: hidden;
  border-bottom: 3px solid #efefef;
}

ul.professorList {
  position: relative;
  overflow: hidden;
  margin: 0 -30px 0 0;
}
ul.professorList li {
  /* display: inline-block; */
  float: left;
  overflow: hidden;
  width: 355px;
  padding: 18px 30px 18px 0;
  border-top: 3px solid #efefef;
}
ul.professorList li .pic {
  float: left;
  width: 125px;
  margin-right: 20px;
}
ul.professorList li .pic img {
  width: 100%;
}
ul.professorList li .pic img.noimage {
  width: 98%;
  border: solid 1px #dfdfdf;
}
ul.professorList li .text {
  line-height: 1.6;
  float: right;
  width: 210px;
  padding-top: 11px;
}
ul.professorList li .text .job {
  font-size: 89%;
  padding-bottom: 16px;
  color: #5e5e5e;
}
ul.professorList li .text h2 {
  font-size: 158%;
  font-weight: normal;
}
ul.professorList li .text h2 a {
  color: #222;
}
ul.professorList li .text h2 .roman {
  font-size: 73%;
}

@media (max-width: 47.99em) {
  #professor .professorListSection {
    overflow: visible;
    width: auto;
    margin: 0 -10px;
    border-bottom: 1px solid #efefef;
  }
  ul.professorList {
    margin: 0;
  }
  ul.professorList li {
    display: block;
    float: none;
    width: auto;
    height: auto !important;
    padding: 10px;
    border-top: 1px solid #efefef;
  }
  ul.professorList li div,
  ul.professorList li p {
    text-align: left;
  }
  ul.professorList li .pic {
    width: 38%;
    margin: 0 4% 0 0;
    text-align: center;
  }
  ul.professorList li .text {
    float: none;
    overflow: hidden;
    width: auto;
    padding-top: 3px;
  }
  ul.professorList li .text .job {
    font-size: 86%;
    padding-bottom: 10px;
  }
  ul.professorList li .text h2 {
    font-size: 115%;
    font-weight: bold;
  }
  ul.professorList li .text h2 .roman {
    font-size: 75%;
  }
}
/* screen and (max-width:640px) */
/* -----------------------------------
	detail
----------------------------------- */
#professor .professorDetailSection {
  overflow: hidden;
  padding: 25px 0;
  border-top: 3px solid #efefef;
  border-bottom: 3px solid #efefef;
}
#professor .professorDetailSection .pic {
  float: left;
  width: 240px;
  text-align: center;
}
#professor .professorDetailSection .pic img {
  width: 100%;
}
#professor .professorDetailSection .text {
  float: right;
  width: calc(100% - 240px);
  padding-left: 2em;
}
#professor .professorDetailSection .text .job {
  font-size: 89%;
  padding-top: 8px;
  padding-bottom: 16px;
  color: #5e5e5e;
}
#professor .professorDetailSection .text .attent {
  margin-top: 15px;
}
#professor .professorDetailSection .text h2 {
  font-size: 179%;
  font-weight: normal;
  padding-bottom: 1em;
}
#professor .professorDetailSection .text h2 .roman {
  font-size: 68%;
  padding-left: 1em;
}
#professor .professorDetailSection .text .profile {
  letter-spacing: 0.08em;
}
#professor .classSection {
  padding-top: 18px;
}

@media (max-width: 47.99em) {
  #professor .professorDetailSection {
    overflow: hidden;
    width: auto;
    padding: 10px 0 0 0;
    border-top: 1px solid #efefef;
    border-bottom: none;
  }
  #professor .professorDetailSection div,
  #professor .professorDetailSection p {
    text-align: left;
  }
  #professor .professorDetailSection .pic {
    width: 38%;
    margin: 0 4% 10px 0;
  }
  #professor .professorDetailSection .text {
    float: none;
    width: auto;
  }
  #professor .professorDetailSection .text .job {
    font-size: 86%;
    padding: 10px 0;
  }
  #professor .professorDetailSection .text h2 {
    font-size: 129%;
    font-weight: bold;
    padding-bottom: 1em;
  }
  #professor .professorDetailSection .text h2 .roman {
    font-size: 75%;
    display: block;
    margin-top: 5px;
    padding-left: 0;
  }
  #professor .professorDetailSection .text .profile {
    clear: both;
    text-align: justify;
    letter-spacing: 0;
  }
  #professor .classSection {
    padding-top: 18px;
  }
}
/* screen and (max-width:640px) */
/* ============================================================

	style info : 教室のスタイルを記述

============================================================ */
/* -----------------------------------
	classroom list
----------------------------------- */
#classroom .navTop {
  margin-bottom: 13px;
}
#classroom .classroomListSection {
  overflow: hidden;
  padding: 35px 0;
  border-top: 3px solid #efefef;
  border-bottom: 3px solid #efefef;
}

ul.classroomList {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  margin-right: 0;
  margin-left: 0;
}
ul.classroomList li {
  flex: 0 0 50%;
  width: 100%;
  max-width: 50%;
  display: block;
  overflow: hidden;
  width: 100%;
  padding: 0 0.5rem 0.5rem;
  letter-spacing: normal;
}
@media (min-width: 62em) {
  ul.classroomList li {
    flex: 0 0 20%;
    width: 100%;
    max-width: 20%;
  }
}
ul.classroomList li .pic {
  position: relative;
  z-index: 1;
  overflow: hidden;
  padding-top: 100%;
  transform: translate3d(0, 0, 0);
  background-color: #f1f1f1;
  overflow: hidden;
  width: 100%;
  text-align: center;
  border: 1px solid #e8e8e8;
}
ul.classroomList li .pic > img {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: auto;
  position: relative;
  display: block;
  width: 100%;
  height: auto;
  transform: translate3d(0, 0, 0);
}
@supports (object-fit: cover) {
  ul.classroomList li .pic > img {
    position: absolute;
    object-fit: cover;
    height: 100%;
    object-position: center;
  }
}
ul.classroomList li .pic img {
  margin: auto !important;
}
ul.classroomList li .pic img.noimage {
  width: 100%;
}
ul.classroomList li .text {
  font-size: 93%;
  line-height: 1.5;
  width: 160px;
  padding-top: 12px;
}

.ieOld ul.classroomList li {
  display: inline;
  zoom: 1;
}

/* screen and (max-width:640px) */
/* -----------------------------------
	shop list
----------------------------------- */
#classroom .leadSection .desc {
  font-weight: normal;
}
#classroom .navTop {
  margin-bottom: 13px;
}
#classroom .shopListSection {
  overflow: hidden;
  border-bottom: 3px solid #efefef;
}
#classroom .classroomDetailSection {
  /* overflow: hidden; */
  padding: 25px 0 30px;
  border-top: 3px solid #efefef;
  border-bottom: 3px solid #efefef;
}
#classroom .classroomDetailSection .pic {
  float: right;
  width: 200px;
  padding: 0 0 32px 32px;
  text-align: right;
  background: #fff;
}
#classroom .classroomDetailSection .pic img {
  width: 100%;
}
#classroom .classroomDetailSection .pic img.noimage {
  width: 98%;
  border: 1px solid #e8e8e8;
}
#classroom .classroomDetailSection h2 {
  font-size: 179%;
  font-weight: normal;
  line-height: 1.3;
  padding-bottom: 14px;
  letter-spacing: 0.1em;
}
#classroom .classroomDetailSection .address {
  margin-bottom: 10px;
}
#classroom .classroomDetailSection .mapLink {
  margin-top: 12px;
  margin-bottom: 15px;
}
#classroom .classroomDetailSection .mapLink a {
  width: auto;
  text-decoration: none;
}
#classroom .classroomDetailSection .intro {
  clear: both;
  margin: 22px 0 0;
  padding: 20px 0 0;
  border-top: 1px solid #efefef;
}
#classroom .classroomDetailSection .intro img {
  display: block;
  margin: 20px 0;
}
#classroom .classroomDetailSection .intro strong {
  display: block;
  margin: 30px 0 10px 0;
}
#classroom .classroomDetailSection .intro .amenity {
  overflow: hidden;
  margin-bottom: 20px;
}
#classroom .classroomDetailSection .intro .amenity dt {
  font-weight: bold;
  float: left;
  color: #00b458;
}
#classroom .classSection {
  padding-top: 18px;
}

@media (max-width: 47.99em) {
  #classroom .shopListSection {
    overflow: visible;
    width: auto;
  }
}
/* screen and (max-width:640px) */
/* -----------------------------------
	detail
----------------------------------- */
@media (max-width: 47.99em) {
  #classroom .classroomDetailSection {
    overflow: hidden;
    width: auto;
    padding: 25px 0 30px;
    border-top: 3px solid #efefef;
    border-bottom: 3px solid #efefef;
  }
  #classroom .classroomDetailSection .pic {
    float: none;
    width: auto;
    margin: 0 20%;
    padding: 0;
  }
  #classroom .classroomDetailSection .pic img {
    border: 1px solid #e8e8e8;
  }
  #classroom .classroomDetailSection h2 {
    font-size: 143%;
    font-weight: bold;
    line-height: 1.3;
    margin-top: 15px;
    padding-bottom: 14px;
    text-align: left;
    letter-spacing: 0.1em;
  }
  #classroom .classroomDetailSection .mapLink {
    margin-top: 12px;
  }
  #classroom .classroomDetailSection .mapLink a {
    text-decoration: none;
  }
  #classroom .classroomDetailSection .intro {
    margin: 22px 0 0;
    padding: 20px 0 0;
    border-top: 1px solid #efefef;
  }
  #classroom .classroomDetailSection .intro img {
    margin: 10px 0;
  }
  #classroom .classroomDetailSection .intro .amenity {
    margin-bottom: 20px;
  }
  #classroom .classroomDetailSection .intro .amenity dt {
    font-weight: bold;
    color: #00b458;
  }
  #classroom .classSection {
    padding-top: 18px;
  }
}
/* screen and (max-width:640px) */
/* ============================================================

	style info : 授業レポートのスタイルを記述

============================================================ */
/* -----------------------------------
	list
----------------------------------- */
#report .classGridListSection {
  overflow: hidden;
  padding: 35px 0;
  border-top: 3px solid #efefef;
  border-bottom: 3px solid #efefef;
}
#report ul.classGridList {
  zoom: 1;
}
#report ul.classGridList li a {
  padding: 0;
  text-decoration: underline;
  border: none;
}
#report ul.classGridList li a:hover {
  text-decoration: none;
  opacity: 0.7;
  border: none;
  filter: alpha(opacity=70);
  -moz-opacity: 0.7;
}
#report ul.classGridList li p.pic {
  text-align: center;
  border: 1px solid #dedede;
}
#report ul.classGridList li p.pic img {
  width: auto;
}
#report .reportDetailSection .textReport h3 {
  font-size: 120%;
  color: #008000;
}
#report .reportDetailSection .textReport em {
  font-weight: normal !important;
  font-style: italic !important;
}
#report .reportDetailSection .textReport em strong {
  font-weight: bold !important;
  font-style: italic !important;
}
#report .reportDetailSection .textReport strong em {
  font-weight: bold !important;
  font-style: italic !important;
}
#report .reportDetailSection .textReport .movecont {
  position: relative;
  width: 100%;
  padding-top: 56.25%;
}
#report .reportDetailSection .textReport .movecont iframe {
  position: absolute;
  top: 0;
  right: 0;
  width: 100% !important;
  height: 100% !important;
}
#report .lightNavSection {
  overflow: hidden;
  margin: 0;
  padding: 10px 0 11px;
  border-top: 3px solid #efefef;
}
#report .lightNavSection ul,
#report .lightNavSection p {
  margin-bottom: 0;
}
#report .lightNavSection .backLink {
  float: left;
  padding-left: 3px;
}
#report .lightNavSection ul.pagerLight {
  float: right;
  width: 200px;
  text-align: right;
}
#report .reportDetailSection {
  padding-bottom: 21px;
  border-top: 3px solid #efefef;
}
#report .reportDetailSection .date {
  font-size: 72%;
  font-weight: bold;
  padding: 0.8em 0 0;
  text-align: right;
  letter-spacing: 0.055em;
}
#report .reportDetailSection h2 {
  font-size: 179%;
  padding: 0.3em 0 0.8em;
  text-align: center;
  letter-spacing: 0.08em;
}
#report .photoReport {
  position: relative;
  width: 640px;
  margin: 0 auto;
  padding: 0 0 2em;
}
#report .photoReport .pointer {
  display: none;
}
#report .photoReport ul.slide {
  overflow: hidden;
  margin-bottom: 15px;
  /* 	height:480px; */
  text-align: center;
}
#report .photoReport ul.slide li {
  text-align: center;
  outline: 0;
  background: #fff;
}
#report .photoReport ul.slide li.current {
  display: block;
}
#report .photoReport ul.slide li img {
  display: inline-block;
  width: auto;
  max-width: 100%;
  max-height: 480px;
  vertical-align: top;
}
#report .photoReport .thumb {
  position: relative;
  margin: 0 24px 0 31px;
}

@media (max-width: 47.99em) {
  #report .classGridListSection {
    overflow: visible;
    width: auto;
    padding: 0;
    border-top: 1px solid #efefef;
    border-bottom: 1px solid #efefef;
  }
  #report ul.classGridList li a {
    padding: 5px 25px 5px 0;
  }
  #report ul.classGridList li p.pic img {
    max-width: 100%;
    max-height: 100px;
  }
}
/* screen and (max-width:640px) */
/* -----------------------------------
	detail
----------------------------------- */
.ieOld #report .photoReport .thumb {
  zoom: 1;
}

#report .photoReport .thumb ul {
  overflow: visible;
  height: 82px;
}
#report .photoReport .thumb ul li {
  float: left;
  width: 110px;
  height: 82px;
  padding: 0 7px 0 0;
  outline: 0;
}
#report .photoReport .thumb ul li a {
  position: relative;
  z-index: 100;
  display: block;
}
#report .photoReport .thumb ul li.is-current span {
  position: absolute;
  z-index: 200;
  top: 0;
  left: 0;
  display: block;
  width: 100%;
  height: 100%;
  border: 3px solid #00b458;
  background: transparent;
}
#report .photoReport .thumb .nav {
  display: none;
}
#report .photoReport .thumb .slick-arrow {
  position: absolute;
  top: 0;
  display: block;
  overflow: hidden;
  width: 23px;
  height: 82px;
  padding: 0;
  cursor: pointer;
  text-indent: 200%;
  border: none;
  outline: 0;
}
#report .photoReport .thumb .slick-arrow.slick-disabled {
  cursor: default;
}
#report .photoReport .thumb .slick-prev {
  left: -31px;
  background: url(../../img/common/arw_basic_grn03_l.png) no-repeat 7px center #e8e8e8;
}
#report .photoReport .thumb .slick-prev.slick-disabled {
  background: url(../../img/common/arw_basic_wht_l.png) no-repeat 7px center #ebebeb;
}
#report .photoReport .thumb .slick-next {
  right: -24px;
  background: url(../../img/common/arw_basic_grn03_r.png) no-repeat 9px center #e8e8e8;
}
#report .photoReport .thumb .slick-next.slick-disabled {
  background: url(../../img/common/arw_basic_wht_r.png) no-repeat 9px center #ebebeb;
}
#report .textReport {
  max-width: 60rem;
  margin: 0 auto 1rem;
  padding-bottom: 0.9em;
}
#report .textReport p {
  line-height: 1.7;
  padding-bottom: 1.1em;
}
#report .textReport img {
  display: block;
  max-width: 100%;
  height: auto;
  margin: 1em auto;
}
#report .dataLink {
  margin-bottom: 20px;
  text-align: center;
}
#report .dataLink a {
  width: 206px;
  margin: 0 auto;
  border-radius: 0;
}

@media (max-width: 47.99em) {
  #report .lightNavSection {
    font-size: 86%;
  }
  #report .lightNavSection ul.pagerLight {
    width: auto;
  }
  #report .reportDetailSection h2 {
    font-size: 134%;
    padding: 0.5em 0;
    letter-spacing: normal;
  }
  #report .photoReport {
    overflow: hidden;
    width: auto;
  }
  #report .photoReport .pointer {
    display: block;
  }
  #report .photoReport ul.slide {
    height: auto;
  }
  #report .photoReport ul.slide li {
    display: block !important;
    float: left;
    width: auto;
    height: auto;
    text-align: center;
  }
  #report .photoReport ul.slide li img {
    display: inline-block;
    width: auto;
    max-width: 100%;
    max-height: 266px;
  }
  #report .photoReport .thumb {
    display: none;
  }
  #report .photoReport ul.slide .slick-dots {
    line-height: 0;
    margin-top: 10px;
    text-align: center;
    vertical-align: top;
  }
  #report .photoReport ul.slide .slick-dots li {
    position: relative;
    display: inline-block !important;
    float: none;
    margin: 0 4px;
    vertical-align: top;
  }
  #report .photoReport ul.slide .slick-dots li button {
    font-size: 0;
    line-height: 0;
    display: block;
    width: 8px;
    height: 8px;
    padding: 0;
    cursor: pointer;
    color: transparent;
    border: 0;
    border-radius: 4px;
    outline: none;
    background: #ccc;
  }
  #report .photoReport ul.slide .slick-dots li.slick-active button {
    background: #00b458;
  }
}
/* screen and (max-width:640px) */
/* ============================================================

	style info : キャンパスライフのスタイルを記述

============================================================ */
/* -----------------------------------
	list
----------------------------------- */
#campuslife .leadSection .desc {
  font-weight: normal;
}
#campuslife .navTop {
  margin-bottom: 13px;
}
#campuslife .contents-main,
#campuslife .fileListSection {
  overflow: hidden;
}
#campuslife .contents-main ul.fileList li,
#campuslife .fileListSection ul.fileList li {
  overflow: hidden;
  padding: 18px 0;
  border-top: 3px solid #efefef;
}
#campuslife .contents-main ul.fileList li .pic,
#campuslife .fileListSection ul.fileList li .pic {
  float: left;
  width: 150px;
}
#campuslife .contents-main ul.fileList li .text,
#campuslife .fileListSection ul.fileList li .text {
  line-height: 1.5;
  float: right;
  overflow: hidden;
  width: 565px;
  padding-top: 7px;
}
#campuslife .contents-main ul.fileList li .text .date,
#campuslife .fileListSection ul.fileList li .text .date {
  font-size: 72%;
  font-weight: bold;
  float: right;
  text-align: right;
}
#campuslife .contents-main ul.fileList li .text .fileNo,
#campuslife .fileListSection ul.fileList li .text .fileNo {
  font-family: "Lato", sans-serif;
  font-size: 93%;
  letter-spacing: 0.08em;
  color: #00a350;
}
#campuslife .contents-main ul.fileList li .text h2,
#campuslife .fileListSection ul.fileList li .text h2 {
  font-size: 158%;
  font-weight: normal;
  width: 100%;
  padding-bottom: 0.2em;
}
#campuslife .contents-main ul.fileList li .text h2 a,
#campuslife .fileListSection ul.fileList li .text h2 a {
  text-decoration: underline;
  color: #222;
}
#campuslife .contents-main ul.fileList li .text h2 a:hover, #campuslife .contents-main ul.fileList li .text h2 a:active,
#campuslife .fileListSection ul.fileList li .text h2 a:hover,
#campuslife .fileListSection ul.fileList li .text h2 a:active {
  text-decoration: none;
}
#campuslife .contents-main ul.fileList li .text .role,
#campuslife .fileListSection ul.fileList li .text .role {
  font-size: 93%;
}
#campuslife .contents-main ul.fileList li .text .quote,
#campuslife .fileListSection ul.fileList li .text .quote {
  font-size: 93%;
  width: 380px;
  padding-top: 1.3em;
}
#campuslife .lightNavSection {
  overflow: hidden;
  margin: 0;
  padding: 10px 0 11px;
  border-top: 3px solid #efefef;
}
#campuslife .lightNavSection .backLink {
  float: left;
  padding-left: 3px;
}
#campuslife .lightNavSection ul.pagerLight {
  float: right;
  width: 200px;
  text-align: right;
}
#campuslife .fileDataSection {
  overflow: hidden;
  padding: 28px 0 21px;
  border-top: 3px solid #efefef;
}
#campuslife .fileDataSection .pic {
  float: left;
  width: 180px;
}
#campuslife .fileDataSection .text {
  line-height: 1.5;
  float: right;
  overflow: hidden;
  width: 535px;
}
#campuslife .fileDataSection .text .fileNo {
  font-family: "Lato", sans-serif;
  font-size: 115%;
  line-height: 1.2;
  letter-spacing: 0.08em;
  color: #00a350;
}
#campuslife .fileDataSection .text h2 {
  font-size: 172%;
  font-weight: normal;
  width: 100%;
  padding-bottom: 0.1em;
  letter-spacing: 0.08em;
}
#campuslife .fileDataSection .text .role {
  font-size: 93%;
}
#campuslife .fileDataSection .text .quote {
  font-size: 136%;
  line-height: 1.3;
  padding: 0.9em 0 0;
}
#campuslife .fileDataSection .text .summary {
  padding: 0.8em 0 0;
}
#campuslife .fileDetailSection {
  border-top: 1px solid #efefef;
}
#campuslife .contents-main .int_q_blk .int_q,
#campuslife .contents-main .int_a_blk .int_a,
#campuslife .contents-main .int_q_blk .int_qtxt,
#campuslife .contents-main .int_a_blk .int_atxt,
#campuslife .fileDetailSection .int_q_blk .int_q,
#campuslife .fileDetailSection .int_a_blk .int_a,
#campuslife .fileDetailSection .int_q_blk .int_qtxt,
#campuslife .fileDetailSection .int_a_blk .int_atxt {
  overflow: hidden;
  padding-top: 1.2em;
  vertical-align: top;
}
#campuslife .contents-main .int_q_blk .int_q,
#campuslife .contents-main .int_a_blk .int_a,
#campuslife .fileDetailSection .int_q_blk .int_q,
#campuslife .fileDetailSection .int_a_blk .int_a {
  font-weight: bold;
  display: inline-block;
  width: 90px;
  margin-right: 10px;
  text-align: right;
}
#campuslife .contents-main .int_q_blk .int_qtxt,
#campuslife .contents-main .int_a_blk .int_atxt,
#campuslife .fileDetailSection .int_q_blk .int_qtxt,
#campuslife .fileDetailSection .int_a_blk .int_atxt {
  display: inline-block;
  width: calc(100% - 100px);
  padding-top: 1.2em;
}

@media (max-width: 47.99em) {
  #campuslife .fileListSection {
    overflow: hidden;
    width: auto;
  }
  #campuslife .fileListSection ul.fileList li {
    overflow: hidden;
    padding: 10px 0;
    border-top: 1px solid #efefef;
  }
  #campuslife .fileListSection ul.fileList li .pic {
    float: left;
    width: 28%;
    margin: 0 4% 0 0;
  }
  #campuslife .fileListSection ul.fileList li .text {
    float: none;
    overflow: hidden;
    width: auto;
    padding-top: 0;
  }
  #campuslife .fileListSection ul.fileList li .text .date {
    font-size: 72%;
    font-weight: bold;
    float: right;
    text-align: right;
  }
  #campuslife .fileListSection ul.fileList li .text .fileNo {
    font-family: "Lato", sans-serif;
    font-size: 93%;
    letter-spacing: 0.08em;
    color: #00a350;
  }
  #campuslife .fileListSection ul.fileList li .text h2 {
    font-size: 129%;
    font-weight: normal;
    width: 100%;
    padding-bottom: 0.2em;
  }
  #campuslife .fileListSection ul.fileList li .text h2 a {
    text-decoration: underline;
    color: #222;
  }
  #campuslife .fileListSection ul.fileList li .text h2 a:hover, #campuslife .fileListSection ul.fileList li .text h2 a:active {
    text-decoration: none;
  }
  #campuslife .fileListSection ul.fileList li .text .role {
    font-size: 93%;
  }
  #campuslife .fileListSection ul.fileList li .text .quote {
    font-size: 93%;
    width: auto;
    padding-top: 10px;
  }
}
/* screen and (max-width:640px) */
/* -----------------------------------
	detail
----------------------------------- */
.ieOld #campuslife .fileDetailSection .int_q_blk .int_q,
.ieOld #campuslife .fileDetailSection .int_a_blk .int_a,
.ieOld #campuslife .fileDetailSection .int_q_blk .int_qtxt,
.ieOld #campuslife .fileDetailSection .int_a_blk .int_atxt {
  display: inline;
  zoom: 1;
}

#campuslife .snapL {
  float: left;
  padding: 0 32px 32px 0;
}
#campuslife .snapR {
  float: right;
  padding: 0 0 32px 32px;
}
#campuslife .fileDetailSection .date {
  font-size: 72%;
  font-weight: bold;
  padding: 2em 0 0;
  text-align: right;
  letter-spacing: 0.055em;
}
#campuslife .profileSection {
  margin: 1.5em 0 36px;
}
#campuslife .profileSection dl {
  padding: 20px 19px 18px;
  background-color: #f4f4f4;
}
#campuslife .profileSection dl dt {
  font-size: 86%;
  padding-bottom: 0.8em;
}
#campuslife .profileSection dl dt span {
  font-family: "Lato", sans-serif;
  font-size: 116%;
  padding-right: 0.4em;
  letter-spacing: 0.08em;
  color: #00a350;
}
#campuslife .profileSection dl dd p {
  font-size: 86%;
}
#campuslife .profileSection dl dd p.referLink {
  padding: 0.8em 0 0;
}
#campuslife .profileSection dl dd p.referLink a {
  font-size: 92%;
  display: inline-block;
  padding: 7px 18px 6px 28px;
  background: url(../../img/common/arw_basic_grn04_r.png) 14px center no-repeat #fff;
}

.ieOld #campuslife .profileSection dl dd p.referLink a {
  display: inline;
  zoom: 1;
}

@media (max-width: 47.99em) {
  #campuslife .lightNavSection {
    overflow: hidden;
    margin: 0;
    padding: 10px 0 11px;
    border-top: 3px solid #efefef;
  }
  #campuslife .lightNavSection .backLink {
    float: left;
    padding-left: 3px;
  }
  #campuslife .lightNavSection ul.pagerLight {
    float: right;
    width: 200px;
    text-align: right;
  }
  #campuslife .fileDataSection {
    overflow: hidden;
    padding: 28px 0 21px;
    border-top: 3px solid #efefef;
  }
  #campuslife .fileDataSection .pic {
    float: left;
    width: 38%;
    margin: 0 4% 0 0;
  }
  #campuslife .fileDataSection .text {
    line-height: 1.5;
    float: none;
    overflow: visible;
    width: auto;
  }
  #campuslife .fileDataSection .text .fileNo {
    font-family: "Lato", sans-serif;
    font-size: 115%;
    line-height: 1.2;
    margin-bottom: 5px;
    letter-spacing: 0.08em;
    color: #00a350;
  }
  #campuslife .fileDataSection .text h2 {
    font-size: 149%;
    font-weight: normal;
    width: 100%;
    padding-bottom: 0;
    letter-spacing: 0.08em;
  }
  #campuslife .fileDataSection .text .role {
    font-size: 86%;
  }
  #campuslife .fileDataSection .text .quote {
    font-size: 129%;
    line-height: 1.5;
    clear: both;
    padding: 15px 0 0 0;
  }
  #campuslife .fileDataSection .text .summary {
    padding: 0.8em 0 0;
  }
  #campuslife .fileDetailSection {
    padding: 0.5em 0 0;
    border-top: 1px solid #efefef;
  }
  #campuslife .fileDetailSection .int_q_blk .int_q,
  #campuslife .fileDetailSection .int_a_blk .int_a,
  #campuslife .fileDetailSection .int_q_blk .int_qtxt,
  #campuslife .fileDetailSection .int_a_blk .int_atxt {
    overflow: hidden;
    padding-top: 1.2em;
    vertical-align: top;
  }
  #campuslife .fileDetailSection .int_q_blk .int_q,
  #campuslife .fileDetailSection .int_a_blk .int_a {
    font-size: 100%;
    font-weight: bold;
    display: block;
    width: auto;
    margin: 0 0 -1.2em 0;
    text-align: left;
  }
  #campuslife .fileDetailSection .int_q_blk .int_qtxt,
  #campuslife .fileDetailSection .int_a_blk .int_atxt {
    display: inline-block;
    width: auto;
    padding-top: 1.6em;
  }
  .ieOld #campuslife .fileDetailSection .int_q_blk .int_q,
  .ieOld #campuslife .fileDetailSection .int_a_blk .int_a,
  .ieOld #campuslife .fileDetailSection .int_q_blk .int_qtxt,
  .ieOld #campuslife .fileDetailSection .int_a_blk .int_atxt {
    display: inline;
    zoom: 1;
  }
  #campuslife .fileDetailSection .snapL {
    float: left;
    padding: 0 32px 32px 0;
  }
  #campuslife .fileDetailSection .snapR {
    float: right;
    padding: 0 0 32px 32px;
  }
  #campuslife .fileDetailSection .date {
    font-size: 86%;
    font-weight: bold;
    padding: 2em 0 0;
    text-align: right;
    letter-spacing: 0.055em;
  }
  #campuslife .profileSection {
    margin: 1.5em 0 36px;
  }
  #campuslife .profileSection dl {
    padding: 20px 19px 18px;
    background-color: #f4f4f4;
  }
  #campuslife .profileSection dl dt {
    font-size: 86%;
    padding-bottom: 0.8em;
  }
  #campuslife .profileSection dl dt span {
    font-family: "Lato", sans-serif;
    font-size: 116%;
    padding-right: 0.4em;
    letter-spacing: 0.08em;
    color: #00a350;
  }
  #campuslife .profileSection dl dd p {
    font-size: 86%;
  }
  #campuslife .profileSection dl dd p.referLink {
    padding: 0.8em 0 0;
  }
  #campuslife .profileSection dl dd p.referLink a {
    font-size: 92%;
    display: inline-block;
    padding: 7px 18px 6px 28px;
    background: url(../../img/common/arw_basic_grn04_r.png) 14px center no-repeat #fff;
  }
  .ieOld #campuslife .profileSection dl dd p.referLink a {
    display: inline;
    zoom: 1;
  }
}
/* screen and (max-width:640px) */
/* ============================================================

	style info : しごと課のスタイルを記述

============================================================ */
/* common */
#shigoto h2.basicTitle02 {
  font-size: 115%;
  color: #00a350;
}
#shigoto .aboutSection {
  padding: 24px 0;
  border-top: 3px solid #efefef;
}
#shigoto .aboutSection p.catch {
  font-size: 143%;
  font-weight: bold;
  padding: 0 0 0.6em;
  letter-spacing: 0.08em;
}
#shigoto .aboutSection h2 a {
  font-weight: normal;
  line-height: 1.2;
  display: inline-block;
  padding-left: 26px;
  background: url(../../img/common/icn_closed.png) no-repeat left top;
}

/* aboutSection */
.ieOld #shigoto .aboutSection h2 a {
  display: inline;
  zoom: 1;
}

#shigoto .aboutSection .desc {
  display: none;
}
#shigoto .aboutSection .open h2 a {
  background: url(../../img/common/icn_open.png) no-repeat left top;
}
#shigoto .aboutSection .open .desc {
  display: block;
  padding: 1em 0 0 26px;
}
#shigoto .aboutSection .open .desc p {
  padding-bottom: 1em;
}
#shigoto .aboutSection .open .desc p.last {
  margin-bottom: 1.4em;
  border-bottom: 1px solid #efefef;
}
#shigoto .aboutSection .open .desc p.author {
  padding-bottom: 0;
}
#shigoto .aboutSection .open .desc h3 {
  margin: 0.6em 0;
}
#shigoto .aboutSection .open .desc ul {
  margin-bottom: 1em;
}
#shigoto .aboutSection .open .desc ul li {
  padding: 0 0 0 0.2em;
  list-style: disc inside none;
}
#shigoto .newsListSection {
  padding: 2em 0 0;
  border-top: 3px solid #efefef;
}
#shigoto .newsListSection h2 {
  font-family: "Lato", sans-serif;
  font-size: 129%;
  font-weight: normal;
  float: left;
  width: 150px;
  margin-bottom: 3px;
  letter-spacing: 0.08em;
  color: #00a350;
}
#shigoto .newsListSection .snsList {
  font-size: 79%;
  line-height: 1;
  float: right;
  width: 400px;
  padding: 8px 0 0;
  text-align: right;
  letter-spacing: 0.15em;
}
#shigoto .newsListSection .snsList p.lead {
  display: inline-block;
}
#shigoto .newsListSection .snsList ul {
  display: inline-block;
}
#shigoto .newsListSection .snsList ul li {
  display: inline-block;
  margin-left: 10px;
  text-align: left;
}
#shigoto .newsListSection .snsList ul li a {
  padding-left: 12px;
  text-decoration: none;
  background: url(../../img/common/arw_basic_grn04_r.png) no-repeat left 2px;
}
#shigoto .newsListSection .snsList ul li a:hover {
  text-decoration: underline;
}
#shigoto .newsListSection .newsList dl {
  border-top: 1px solid #efefef;
}
#shigoto .newsListSection .diaryLink {
  line-height: 1;
  padding: 9px 0 0;
}
#shigoto .newsListSection .diaryLink a {
  line-height: 32px;
  display: inline-block;
  height: 32px;
}
#shigoto .newsListSection .diaryLink a.basicButton02 {
  width: 80px;
  margin-right: 17px;
  text-decoration: none;
}

/* newsListSection */
.ieOld #shigoto .newsListSection .snsList p.lead {
  display: inline;
  zoom: 1;
}
.ieOld #shigoto .newsListSection .snsList ul {
  display: inline;
  zoom: 1;
}
.ieOld #shigoto .newsListSection .snsList ul li {
  display: inline;
  zoom: 1;
}
.ieOld #shigoto .newsListSection .diaryLink a {
  display: inline;
  zoom: 1;
}

/* classSection */
#shigoto .classSection {
  position: relative;
  margin: 31px 0 0;
  padding: 2em 0 0;
  border-top: 3px solid #efefef;
}
#shigoto .classSection p.lead {
  line-height: 1.7;
  padding: 0 0 1.6em;
}
#shigoto .jobListSection {
  padding: 2em 0 0;
  border-top: 3px solid #efefef;
}
#shigoto .jobListSection p.lead {
  line-height: 1.7;
  padding: 0 0 1.6em;
}
#shigoto .jobListSection ul.jobList {
  line-height: 1;
  margin: 0 -20px 0 0;
  letter-spacing: -0.4em;
}
#shigoto .jobListSection ul.jobList li {
  display: inline-block;
  width: 132px;
  padding: 0 20px 20px 0;
  letter-spacing: normal;
}
#shigoto .jobListSection p.note {
  font-size: 79%;
  color: #666;
}

/* jobListSection */
.ieOld #shigoto .jobListSection ul.jobList li {
  display: inline;
  zoom: 1;
}

/* contactSection */
#shigoto .contactSection p.note {
  margin: 1em 0 0;
}

@media (max-width: 47.99em) {
  /* aboutSection */
  #shigoto .aboutSection {
    padding: 15px 0;
  }
  #shigoto .aboutSection p.catch {
    font-size: 129%;
    line-height: 1.6;
  }
  #shigoto .aboutSection h2 a {
    font-weight: bold;
    display: block;
  }
  #shigoto .aboutSection .open .desc {
    padding: 1em 0 0 0;
  }
  #shigoto .aboutSection .open .desc p {
    padding-bottom: 1em;
  }
  #shigoto .aboutSection .open .desc ul li {
    line-height: 1.4;
    margin: 0 0 0.5em 1.5em;
    padding: 0;
    list-style: disc outside;
  }
  #shigoto .newsListSection {
    padding: 15px 0;
  }
  #shigoto .newsListSection h2 {
    float: none;
    width: auto;
  }
  #shigoto .newsListSection .snsList {
    font-size: 93%;
    line-height: 1.5;
    float: none;
    width: auto;
    margin: 10px 0;
    padding: 0;
    text-align: left;
  }
  #shigoto .newsListSection .snsList ul li {
    margin: 0;
  }
  #shigoto .newsListSection .snsList ul li a {
    padding-left: 14px;
    background: url(../../img/common/arw_basic_grn04_r.png) no-repeat 2px center;
  }
  #shigoto .newsListSection .diaryLink {
    padding: 15px 0 0 0;
  }
  #shigoto .newsListSection .diaryLink a.article {
    line-height: normal;
    display: block;
    height: auto;
    margin-top: 5px;
  }
  #shigoto .classSection {
    margin: 0;
    padding: 15px 0;
  }
  #shigoto .classSection p.lead {
    line-height: 1.6;
    padding: 0 0 10px 0;
  }
  #shigoto .jobListSection {
    overflow: hidden;
    padding: 15px 0;
  }
  #shigoto .jobListSection p.lead {
    line-height: 1.6;
    padding: 0 0 10px 0;
  }
  #shigoto .jobListSection ul.jobList {
    line-height: normal;
    position: relative;
    overflow: hidden;
    margin: 0 -4% 0 0;
    letter-spacing: normal;
  }
  #shigoto .jobListSection ul.jobList li {
    display: inline;
    float: left;
    width: 46%;
    margin: 0 4% 10px 0;
    padding: 0;
    letter-spacing: normal;
  }
  #shigoto .jobListSection p.note {
    font-size: 86%;
  }
  #shigoto .contactSection {
    margin: 0 0 15px 0;
  }
  /* newsListSection */
  /* classSection */
  /* jobListSection */
  /* contactSection */
}
/* screen and (max-width:640px) */
/* ============================================================

	style info : ニュースのスタイルを記述

============================================================ */
/* screen and (max-width:640px) */
/* ============================================================

	style info : aboutのスタイルを記述

============================================================ */
/* -----------------------------------
	index
----------------------------------- */
/* screen and (max-width:640px) */
/* -----------------------------------
	organization
----------------------------------- */
/* screen and (max-width:640px) */
/* -----------------------------------
	staff
----------------------------------- */
/* screen and (max-width:640px) */
/* -----------------------------------
	press
----------------------------------- */
/* screen and (max-width:640px) */
/* ============================================================

	style info : みんなの活動のスタイルを記述

============================================================ */
/* -----------------------------------
	list
----------------------------------- */
#club .clubSection {
  padding: 27px 0 0;
  border-top: 3px solid #efefef;
}
#club .clubSection.activeClub {
  padding: 27px 0;
}
#club .clubSection.closedClub ul.clubGridList {
  padding: 12px 0 0;
}
#club .clubSection .note {
  font-size: 79%;
}
#club .blog .leadSection {
  margin-bottom: 26px;
  padding-left: 0;
  border-left: none;
}
#club .blog .leadSection h1 {
  line-height: 1;
}
#club .blog .lightNavSection {
  margin: 0 0 11px;
  text-align: right;
}

@media (max-width: 47.99em) {
  #club .clubSection {
    margin: 20px 0 0 0;
    padding: 20px 0 0 0;
    border-top: 3px solid #efefef;
  }
  #club .clubSection.activeClub {
    margin: 0;
    padding: 0;
    border-top: none;
  }
  #club .clubSection.closedClub ul.clubGridList {
    padding: 0;
  }
  #club .clubSection .note {
    margin-top: 10px;
  }
}
/* screen and (max-width:640px) */
/* -----------------------------------
	blog
----------------------------------- */
/* common */
/* main */
/* sub */
/* ============================================================

	style info : よくある質問のスタイルを記述

============================================================ */
#student h2.basicTitle02 {
  font-size: 115%;
  color: #00a350;
}
#student .innerLinkNav {
  padding: 15px 0 0 0;
}
#student .innerLinkNav ul {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding-left: 0;
}
#student .innerLinkNav ul li {
  width: calc(50% - 2%);
}
@media (min-width: 62em) {
  #student .innerLinkNav ul li {
    width: calc(25% - 2%);
  }
}
#student .innerLinkNav ul li a {
  font-size: 0.75rem;
  font-weight: bold;
  display: block;
  width: auto;
  margin-right: 0;
  margin-bottom: 0.5em;
  padding: 0.5em 0.5em 0.5em 2em;
  text-decoration: none;
  letter-spacing: 0;
  background: url(../../img/common/sp/arw_basic_grn_b.png) no-repeat 8px center #f2f2f2;
  background-size: 10px;
}
@media (min-width: 62em) {
  #student .innerLinkNav ul li a {
    font-size: 1rem;
    padding: 1em 1em 1em 2em;
    text-align: center;
    background: url(../../img/common/arw_basic_grn03_b.png) no-repeat 12px center #f2f2f2;
  }
}
#student .faqListSection h2 {
  font-size: 150%;
  font-weight: bold;
  margin-bottom: 0;
  padding: 2em 0 0.9em;
  letter-spacing: 0.1em;
}
#student .faqListSection .faqList {
  padding-left: 0;
  border-top: 3px solid #efefef;
  border-bottom: 2px solid #efefef;
}
#student .faqListSection .faqList li {
  border-bottom: 1px solid #efefef;
}
#student .faqListSection .faqList h3 {
  margin-bottom: 0;
}
#student .faqListSection .faqList h3 a {
  font-size: 1rem;
  font-weight: bold;
  line-height: 1.2;
  display: block;
  padding: 0.9em 0 0.9em 1.9em;
  text-decoration: none;
  background: url(../../img/common/icn_closed.png) no-repeat left 1em;
}
#student .faqListSection .faqList .answer {
  display: none;
}
#student .faqListSection .faqList .is-open h3 a {
  background: url(../../img/common/icn_open.png) no-repeat left 1em;
}
#student .faqListSection .faqList .is-open .answer {
  line-height: 1.7;
  display: block;
  margin: 0 0 1em;
  padding: 1.4em 1.4em 0.6em;
  background-color: #f4f4f4;
}
#student .faqListSection .faqList .is-open .answer p {
  padding: 0 0 0.8em;
}
#student .faqListSection .faqList .is-open .answer p:last-child {
  margin-bottom: 0;
}
#student .contactSection p.moreLink {
  margin: 1em 0 0;
}

/* screen and (max-width:640px) */
/* ============================================================

	style info : プロジェクトのスタイルを記述

============================================================ */
#project .formLead {
  line-height: 1.7;
  margin-bottom: 24px;
  padding-top: 28px;
  border-top: 3px solid #efefef;
}
#project .formLead p {
  font-size: 100%;
  font-weight: normal;
}
#project .projectListSection {
  border-top: 1px solid #efefef;
}
#project .projectListSection .section {
  padding: 34px 0 20px;
  border-bottom: 3px solid #efefef;
}
#project .projectListSection .titleArea {
  overflow: hidden;
  padding: 0 0 28px;
}
#project .projectListSection .titleArea .info {
  float: left;
  width: 121px;
}
#project .projectListSection .titleArea .title {
  font-weight: bold;
  float: left;
  width: 597px;
  padding-left: 22px;
}
#project .projectListSection .no,
#project .projectListSection .year {
  font-weight: bold;
  width: 115px;
  text-align: center;
  border: 3px solid #00b458;
}
#project .projectListSection .no {
  font-family: "Lato", sans-serif;
  font-size: 86%;
  line-height: 2;
  margin-bottom: 4px;
  letter-spacing: 0.03em;
  color: #fff;
  background-color: #00b458;
}
#project .projectListSection .no span {
  font-size: 125%;
}
#project .projectListSection .year {
  font-size: 79%;
  line-height: 1.5;
  padding: 0.3em 0 0;
  color: #00b458;
  background-color: #fff;
}
#project .projectListSection h2 {
  font-size: 166%;
  padding: 6px 0 3px 0;
  color: #00b458;
}
#project .projectListSection h2.lines {
  padding: 0 0 3px 0;
}
#project .projectListSection .with {
  font-size: 93%;
}
#project .projectListSection .pic {
  padding-bottom: 1.5em;
}
#project .projectListSection .desc dl {
  line-height: 1.7;
  padding-bottom: 1em;
}
#project .projectListSection .desc dl dt {
  font-weight: bold;
  color: #00b458;
}

@media (max-width: 47.99em) {
  #project .formLead {
    line-height: inherit;
    margin: 0;
    padding: 10px 0 0 0;
    border-top: 3px solid #efefef;
  }
  #project .projectListSection {
    border: none;
  }
  #project .projectListSection .section {
    position: relative;
    margin: 0 -10px;
    padding: 20px 10px 10px 10px;
  }
  #project .projectListSection .titleArea {
    padding: 0 0 15px;
  }
  #project .projectListSection .titleArea .info {
    float: none;
    width: auto;
    margin-bottom: 10px;
  }
  #project .projectListSection .titleArea .title {
    float: none;
    width: auto;
    padding-left: 0;
  }
  #project .projectListSection .no,
  #project .projectListSection .year {
    font-size: 79%;
    line-height: 1.9em;
    display: inline-block;
    height: 1.8em;
    margin: 0 2px 0 0;
    padding: 0;
    vertical-align: top;
    letter-spacing: normal;
    border: 2px solid #00b458;
  }
  #project .projectListSection .no span {
    line-height: 1;
  }
  #project .projectListSection h2 {
    font-size: 129%;
    padding: 0;
  }
  #project .projectListSection h2.lines {
    padding: 0;
  }
  #project .projectListSection .with {
    margin-top: 5px;
  }
  #project .projectListSection .pic {
    padding-bottom: 1em;
  }
}
/* screen and (max-width:640px) */
/* ============================================================

	style info : フォーム系画面のスタイルを記述

============================================================ */
/* -----------------------------------
	common
----------------------------------- */
.formSection {
  padding-top: 30px;
  border-top: solid 3px #efefef;
}
.formSection p.error {
  font-size: 73%;
  font-weight: bold;
  color: #e70808;
}

.formLead {
  line-height: 1.8;
  margin-bottom: 60px;
}
.formLead p {
  font-weight: bold;
}
.formLead p.error {
  margin-top: 5px;
}
.formLead p.arwLink {
  margin-bottom: 1em;
}
.formLead p.arwLink a {
  background-position: 0 0.1em;
}

.check .formContent {
  margin: 0 25px;
}

.formTable tr th,
.formTable tr td {
  font-size: 108%;
  padding: 10px 0;
  vertical-align: middle;
}
.formTable tr th {
  font-weight: bold;
  width: 43%;
  padding-right: 7%;
  text-align: right;
}
.formTable tr.hr th,
.formTable tr.hr td {
  padding-top: 50px;
}
.formTable tr th span.required {
  font-size: 73%;
  color: #e70808;
}

.formContent .freeArea dt span.required {
  font-size: 73%;
  color: #e70808;
}

.formTable tr th .ex {
  display: table;
  width: 100%;
}
.formTable tr th .ex span {
  display: table-cell;
  vertical-align: bottom;
}
.formTable tr th .ex span.required {
  width: 4em;
}
.formTable p.format {
  font-size: 86%;
  color: #6b6767;
}
.formTable tr td p.error, .formTable tr td p.format {
  margin-top: 3px;
}
.formTable input.text {
  padding: 10px;
  border: solid 2px #cfcdcd;
  border-radius: 12px;
  background: #fff9eb;
}

.formSection textarea {
  padding: 10px;
  border: solid 2px #cfcdcd;
  border-radius: 12px;
  background: #fff9eb;
}

.formTable input.text {
  width: 280px;
}
.formTable label {
  margin-right: 2em;
  vertical-align: middle;
}
.formTable label input {
  position: relative;
  top: -1px;
  margin-right: 5px;
  vertical-align: middle;
}

.formAction {
  position: relative;
  margin: 60px 0 0 0;
  text-align: center;
}
.formAction .buttons {
  position: relative;
}
.formAction p.term a {
  font-size: 93%;
  padding-left: 10px;
  text-decoration: none;
  color: #00b458;
  background: url(../../img/common/arw_min_grn_r.png) left 0.3em no-repeat;
}
.formAction p.button, .formAction p.arwLink {
  margin-top: 10px;
}

.formActionButtons .correct {
  position: absolute;
  top: 0;
  left: 0;
}
.formActionButtons .correct .basicButton {
  font-size: 100%;
  width: 88px;
  background: #aaa url(../../img/common/arw_basic_wht_l.png) 16px center no-repeat;
}

.formSection .referLink {
  font-size: 93%;
  font-weight: bold;
  margin-top: 10px;
}

@media (max-width: 47.99em) {
  .formSection {
    padding-top: 0;
  }
  .complete .basicSection {
    margin-bottom: 20px;
    padding: 0 3px;
  }
  .formSection p.error {
    font-size: 86%;
  }
  .formLead {
    line-height: 1.6;
    margin: 10px 0;
  }
  .formLead p {
    font-size: 100%;
    margin-bottom: 10px;
  }
  .formContent {
    position: relative;
    margin: 0 -10px;
  }
  .formTable {
    display: block;
  }
  .formTable tbody {
    display: block;
  }
  .formTable tr {
    display: block;
  }
  .formTable tr th,
  .formTable tr td {
    display: block;
  }
  .formTable tr th,
  .formTable tr td {
    font-size: 100%;
    padding: 0 10px;
    vertical-align: middle;
  }
  .formTable tr th {
    width: auto;
    padding-top: 10px;
    text-align: left;
    border-top: solid 1px #ddd;
  }
  .formTable tr td {
    padding-top: 3px;
    padding-bottom: 10px;
  }
  .formTable tr.hr th {
    padding-top: 10px !important;
  }
  .formTable tr.hr td {
    padding-top: 3px !important;
    padding-bottom: 10px !important;
  }
  .formTable tr th br {
    display: none;
  }
  .formTable tr th .ex {
    display: inline;
  }
  .formTable tr th .ex span {
    display: inline;
    vertical-align: inherit;
  }
  .formTable tr th .ex span.required {
    width: auto;
  }
  .formTable p.format {
    font-size: 86%;
    color: #6b6767;
  }
  .formTable input.text {
    width: 100%;
    padding: 5px 10px;
    border-radius: 10px;
  }
  .formSection textarea {
    width: 90%;
    padding: 5px 10px;
    border-radius: 10px;
  }
  .formAction {
    margin: 10px 0;
  }
  .formAction p.arwLink {
    text-align: left;
  }
  .formActionButtons .button {
    margin-left: 100px;
  }
  .check .formContent {
    margin: 0 -10px;
  }
  .complete .basicSection {
    padding: 10px 0 0 0;
    border-top: solid 3px #efefef;
  }
}
/* screen and (max-width:640px) */
/* -----------------------------------
	regist
----------------------------------- */
.formTable tr.password th, .formTable tr.birth th {
  padding-top: 60px;
  vertical-align: top;
}
.formTable tr.password th,
.formTable tr.password td {
  padding-bottom: 0;
}
.formTable tr.birth th {
  vertical-align: top;
}
.formTable tr.birth td {
  font-size: 100%;
}
.formTable tr.birth td input.text {
  font-size: 108%;
}
.formTable tr.postaddress input.text {
  width: 180px;
}
.formTable tr.birth input.text {
  width: 130px;
}
.formTable tr.password td p, .formTable tr.password2 td p {
  display: inline-block;
  margin-right: 5px;
}
.formTable tr.postaddress td p.error {
  margin-left: 1.9em;
}
.formTable tr.birth td p.error {
  margin-left: 3.2em;
}

.dataTable {
  border-top: dotted 1px #ccc;
  border-right: dotted 1px #ccc;
}
.dataTable tr th,
.dataTable tr td {
  padding: 20px;
}
.dataTable tr th {
  width: 25%;
  text-align: right;
  border-bottom: solid 1px #fff;
  background: #ebeae8;
}
.dataTable tr td {
  border-bottom: dotted 1px #ccc;
}
.dataTable tr.username th {
  border-top: solid 1px #ebeae8;
}
.dataTable tr.relation th {
  border-bottom: solid 1px #ebeae8;
}

@media (max-width: 47.99em) {
  .formTable tr.postaddress input.text {
    width: 8em;
  }
  .formTable tr.birth input.text {
    width: 4em;
  }
  .dataTable {
    border-right: none;
  }
  .dataTable tr th,
  .dataTable tr td {
    padding: 10px;
  }
  .dataTable tr th {
    font-size: 86%;
    width: 28%;
  }
}
/* screen and (max-width:640px) */
/* -----------------------------------
	login
----------------------------------- */
#login .formAction {
  margin: 15px 0 0 43%;
  text-align: left;
}
#login .formAction .basicButton {
  line-height: 44px;
  width: 147px;
  height: 44px;
  background-position: 20px 16px;
}
#login .formAction ul.arwList {
  margin: 10px 0;
}
#login .formAction ul.arwList li a {
  text-decoration: none;
  color: #00b458;
}

@media (max-width: 47.99em) {
  #login .formAction {
    margin: 10px 0;
  }
  #login .formAction .basicButton {
    width: 100%;
  }
}
/* screen and (max-width:640px) */
/* -----------------------------------
	profile
----------------------------------- */
#profile .input .formSection {
  border-top: none;
}

/* -----------------------------------
	entry
----------------------------------- */
#entry .formSection dl {
  line-height: 1.5;
  margin-bottom: 30px;
}
#entry .formSection dl dt {
  font-weight: bold;
  position: relative;
  width: 5.5em;
}
#entry .formSection dl dt span {
  position: absolute;
  top: 0;
  right: 0;
}
#entry .formSection dl dd {
  margin: -1.5em 0 0.2em 6em;
}

/* -----------------------------------
	pass
----------------------------------- */
#pass .formLead {
  margin-bottom: 20px;
}
#pass .formAction {
  margin: 35px 0 0;
}
#pass .sentSection,
#pass .passSection,
#pass .completeSection {
  margin-bottom: 40px;
  padding-top: 30px;
  border-top: 3px solid #efefef;
}
#pass .sentSection .formLead {
  padding-bottom: 0.4em;
}
#pass .sentSection .formLead p {
  margin-bottom: 1em;
}
#pass .formFlowSection {
  padding: 0 0 44px;
}

#regist .formFlowSection {
  padding: 0 0 44px;
}

#pass dl.mailCaution {
  width: 704px;
}

@media (max-width: 47.99em) {
  #pass .formLead {
    margin-bottom: 0;
  }
  #pass .formAction {
    margin: 15px 0 0 0;
  }
  #pass .sentSection,
  #pass .passSection,
  #pass .completeSection {
    margin-bottom: 10px;
    padding-top: 0;
    border-top: none;
  }
  #pass .formFlowSection {
    padding: 0;
  }
  #regist .formFlowSection {
    padding: 0;
  }
  #pass dl.mailCaution {
    width: auto;
  }
}
/* screen and (max-width:640px) */
/* -----------------------------------
	questionnaire
----------------------------------- */
#questionnaire .formLead {
  margin-bottom: 40px;
}
#questionnaire .formLead p {
  font-weight: normal;
}
#questionnaire .formTable {
  width: 94%;
  margin-left: 6%;
}
#questionnaire .formTable tr th {
  font-weight: normal;
  line-height: 1.2;
  width: 31%;
  padding: 10px 0 10px 7%;
  text-align: left;
}
#questionnaire .formTable tr.age input.text {
  width: 70px;
  margin: 0 0.5em 0 0;
}
#questionnaire .formTable textarea {
  width: 425px;
}
#questionnaire .dataTable tr th {
  width: 28%;
}
#questionnaire .privacySection {
  padding: 28px 0 0;
  border-top: 1px solid #b5b9b7;
}
#questionnaire .privacySection dl dt,
#questionnaire .privacySection dl dd {
  font-size: 86%;
  line-height: 1.8;
}
#questionnaire .formAction {
  margin: 40px 25px 0 25px;
}

@media (max-width: 47.99em) {
  #questionnaire .formLead {
    margin: 10px 0;
  }
  #questionnaire .formLead p {
    font-weight: bold;
  }
  #questionnaire .formTable {
    width: 100%;
    margin-left: 0;
    border-bottom: solid 1px #ccc;
  }
  #questionnaire .formTable tr th {
    font-weight: bold;
    line-height: 1.6;
    width: auto;
    padding: 10px 10px 0 10px;
  }
  #questionnaire .formTable textarea {
    width: 100%;
  }
  #questionnaire .formTable tr.opinion_a th, #questionnaire .formTable tr.request_a th {
    width: auto;
  }
  #questionnaire .formTable tr.opinion_a td:first-child, #questionnaire .formTable tr.request_a td:first-child {
    display: none;
  }
  #questionnaire .formTable tr.join label {
    display: block;
  }
  #questionnaire .formTable tr.join br {
    display: none;
  }
  #questionnaire .dataTable tr th {
    width: 28%;
  }
  #questionnaire .privacySection {
    margin: 10px 0 15px 0;
    padding: 0;
    border: none;
  }
  #questionnaire .formAction {
    margin: 10px 0;
  }
}
/* screen and (max-width:640px) */
/* -----------------------------------
	/relation/volunteer.php
----------------------------------- */
/* check */
/* screen and (max-width:640px) */
/* ============================================================

	style info : 参加方法のスタイルを記述

============================================================ */
/* -----------------------------------
	common
----------------------------------- */
@media (max-width: 47.99em) {
  #relation .formLead {
    line-height: inherit;
    margin-bottom: 15px;
    padding-top: 15px;
    border-top: 3px solid #efefef;
  }
}
/* screen and (max-width:640px) */
/* -----------------------------------
	index
----------------------------------- */
.ieOld #relation .index .activitySection dl dt {
  display: inline;
  zoom: 1;
}
.ieOld #relation .index .activitySection dl dt span {
  display: inline;
  zoom: 1;
}
.ieOld #relation .index .activitySection dl dd {
  display: inline;
  zoom: 1;
}

@media (max-width: 47.99em) {
  #relation .index .activitySection {
    margin: 0 0 20px 0;
    padding: 0;
    border-top: none;
  }
  #relation .index .activitySection dl {
    margin-bottom: 20px;
  }
  #relation .index .activitySection dl dt {
    display: block;
    width: auto;
    height: 69px;
    margin: 0 0 15px 0;
    padding: 0 0;
  }
  #relation .index .activitySection dl.learn dt, #relation .index .activitySection dl.play dt, #relation .index .activitySection dl.make dt {
    background-position: -10px center;
    background-size: 120px;
  }
  #relation .index .activitySection dl dt span {
    font-size: 150%;
    line-height: 69px;
    display: block;
    width: auto;
    margin: 0 0 0 100px;
    padding: 0 0 0 15px;
    text-align: left;
    border-left: dotted 3px #ddd;
    background: none;
  }
  #relation .index .activitySection dl dd {
    display: block;
    width: auto;
  }
  #relation .index .registSection h2 {
    font-size: 129%;
  }
  #relation .index .registSection .formLead {
    margin: 0;
    padding: 10px 0 0 0;
  }
}
/* screen and (max-width:640px) */
/* -----------------------------------
	howto
----------------------------------- */
#relation .howto .formLead .backLink {
  padding: 0.6em 0 0;
}
#relation .howto .formLead .backLink a {
  padding-left: 14px;
}
#relation .howto .downFlowSection {
  padding: 24px 0 0;
  border-top: 1px solid #efefef;
}
#relation .howto .downFlowSection h2 {
  font-size: 129%;
  padding: 0 0 24px;
}
#relation .howto .downFlowSection .downFlow {
  position: relative;
  margin-top: -3px;
  padding-bottom: 48px;
  background: url(../../img/common/arw_flow_down.png) no-repeat center bottom;
}
#relation .howto .downFlowSection .downFlow.last {
  padding-bottom: 0;
  background: none;
}
#relation .howto .downFlowSection .downFlow h3 {
  padding: 0 0 6px;
  color: #00b458;
}
#relation .howto .downFlowSection .downFlow .flow {
  width: 100%;
}
#relation .howto .downFlowSection .downFlow .flow th,
#relation .howto .downFlowSection .downFlow .flow td {
  padding: 18px;
}
#relation .howto .downFlowSection .downFlow .flow th {
  font-size: 108%;
  font-weight: bold;
  width: 22%;
  text-align: center;
  vertical-align: middle;
  border-top: 3px solid #e6f6ed;
  border-bottom: 3px solid #e6f6ed;
  background-color: #e6f6ed;
}
#relation .howto .downFlowSection .downFlow .flow td {
  padding: 0 18px 18px;
  border-top: 3px solid #e6f6ed;
  border-right: 3px solid #e6f6ed;
  border-bottom: 3px solid #e6f6ed;
}
#relation .howto .downFlowSection .downFlow .flow td p {
  padding-top: 18px;
}
#relation .howto .downFlowSection .downFlow .flow td dl {
  padding-top: 18px;
}
#relation .howto .downFlowSection .downFlow .flow td dl dd p {
  padding-top: 0;
}
#relation .howto .downFlowSection .downFlow .flow td p.note {
  padding-top: 0.6em;
}
#relation .howto .downFlowSection .downFlow .flow td .inner {
  position: relative;
}
#relation .howto .downFlowSection .downFlow .flow td .pct {
  position: absolute;
}
#relation .howto .downFlowSection .downFlow .flow01 td .pct {
  right: -6px;
  bottom: -18px;
}
#relation .howto .downFlowSection .downFlow .flow02 td .pct {
  right: -12px;
  bottom: -11px;
}
#relation .howto .downFlowSection .downFlow .flow03 td .pct {
  right: -5px;
  bottom: -18px;
}
#relation .howto .downFlowSection .downFlow .flow04 td .pct {
  right: -6px;
  bottom: -7px;
}
#relation .howto .downFlowSection .downFlow .flow05 td .pct {
  right: -10px;
  bottom: -8px;
}
#relation .howto .downFlowSection .downFlow .flow td li {
  line-height: 1.8;
  padding-left: 26px;
}
#relation .howto .downFlowSection .downFlow .flow td li.facebook {
  background: url(../../img/relation/icn_howto_facebook.png) no-repeat left center;
}
#relation .howto .downFlowSection .downFlow .flow td li.twitter {
  background: url(../../img/relation/icn_howto_twitter.png) no-repeat left center;
}
#relation .professor .formLead {
  padding: 1.2em 0 0;
  border-top: 3px solid #efefef;
  background: url(../../img/relation/pct_professor01.png) no-repeat 492px 24px;
}
#relation .professor .formLead .catch {
  font-size: 193%;
  padding: 1em 0;
  letter-spacing: 0.1em;
  color: #00b458;
}
#relation .professor .formLead .desc p {
  line-height: 1.7;
  padding: 0 0 1.2em;
}
#relation .professor .basicSection .leadSection {
  margin: 51px 0 13px;
}
#relation .professor .basicSection .leadSection h2 {
  font-size: 134%;
  font-weight: bold;
  letter-spacing: 0.1em;
}
#relation .professor .downFlowGrySection h2,
#relation .professor .classSection h2 {
  font-size: 134%;
  font-weight: bold;
  letter-spacing: 0.1em;
}
#relation .professor .downFlowGrySection {
  padding: 40px 0;
  border-top: 3px solid #efefef;
}
#relation .professor .downFlowGrySection .desc {
  padding-bottom: 1em;
}

@media (max-width: 47.99em) {
  #relation .howto .downFlowSection .downFlow {
    margin: 0 0 10px 0;
    padding-bottom: 28px;
    background: url(../../img/common/arw_flow_down.png) no-repeat center bottom;
    -webkit-background-size: 60px;
    background-size: 60px;
  }
  #relation .howto .downFlowSection .downFlow .flow th {
    font-size: 100%;
    padding: 10px;
  }
  #relation .howto .downFlowSection .downFlow .flow td {
    padding: 0 10px 10px 10px;
  }
  #relation .howto .downFlowSection .downFlow .flow td .pct {
    display: none;
  }
  #relation .howto .downFlowSection .downFlow .flow td li {
    font-size: 86%;
    margin-top: 5px;
    padding-left: 16px;
  }
  #relation .howto .downFlowSection .downFlow .flow td li.facebook {
    background: url(../../img/relation/icn_howto_facebook.png) no-repeat left 0.5em;
    -webkit-background-size: 10px;
    background-size: 10px;
  }
  #relation .howto .downFlowSection .downFlow .flow td li.twitter {
    background: url(../../img/relation/icn_howto_twitter.png) no-repeat left 0.5em;
    -webkit-background-size: 10px;
    background-size: 10px;
  }
}
/* screen and (max-width:640px) */
/* -----------------------------------
	professor
----------------------------------- */
.downFlowGrySection .downFlowGry {
  margin: 11px 0 0;
  padding: 0 0 29px;
  background: url(../../img/common/arw_flow_grn_down.png) no-repeat center bottom;
}
.downFlowGrySection .downFlowGry.last {
  padding-bottom: 0;
  background: none;
}
.downFlowGrySection .downFlowGry .flow {
  width: 100%;
}
.downFlowGrySection .downFlowGry th,
.downFlowGrySection .downFlowGry td {
  border-top: 3px solid #ddd;
  border-bottom: 3px solid #ddd;
  border-radius: 3px;
}
.downFlowGrySection .downFlowGry th {
  font-family: "Lato", sans-serif;
  font-size: 172%;
  font-weight: normal;
  line-height: 1;
  width: 2em;
  padding: 0.7em 3px 0.7em 0;
  text-align: center;
  border-left: 3px solid #ddd;
  background: url(../../img/common/bdr_3px_ddd_y.png) repeat-y right center;
}
.downFlowGrySection .downFlowGry td {
  padding: 0.8em 1em;
  border-right: 3px solid #ddd;
}

#relation .professor div.note {
  line-height: 1.5;
  padding: 20px 22px;
  background-color: #f4f4f4;
}
#relation .professor .classSection .classGridList .professorName {
  padding: 12px 0 14px;
  border-top: 3px solid #f2f2f2;
}
#relation .professor .classSection .classGridList .professorName h3 {
  margin: 0 0 0.2em;
}
#relation .professor .classSection .classGridList .professorName a {
  font-size: 108%;
  font-weight: bold;
  padding-bottom: 0;
  text-decoration: underline;
  letter-spacing: 0.1em;
  border: none;
}
#relation .professor .classSection .classGridList .professorName a:hover {
  text-decoration: none;
}
#relation .professor .classSection .classGridList .professorName .desc {
  font-size: 79%;
  line-height: 1.4;
}

@media (max-width: 47.99em) {
  #relation .professor .formLead .catch {
    font-size: 134%;
    padding: 0.5em 0 1em 0;
    letter-spacing: 0;
  }
  #relation .professor .downFlowGrySection {
    padding: 20px 0;
    border-top: 3px solid #efefef;
  }
  #relation .professor div.note {
    padding: 15px;
  }
  #relation .professor .classSection .classGridList .professorName {
    padding: 5px 0;
    border-top: none;
  }
}
/* screen and (max-width:640px) */
/* ============================================================

	style info : マイページのスタイルを記述

============================================================ */
/* -----------------------------------
	common
----------------------------------- */
.localNav {
  overflow: hidden;
}

#mypage ul.localNav li {
  width: 195px;
}
#mypage ul.localNav li.withdraw {
  width: 140px;
}
#mypage ul.classList {
  display: block;
  border-bottom: solid 3px #efefef;
}
#mypage ul.classList li .data .date {
  display: block;
  height: auto;
  padding-top: 7px;
  padding-bottom: 5px;
}
#mypage .delete ul.localNav {
  margin-bottom: 40px;
}

@media (max-width: 47.99em) {
  #mypage ul.localNav li {
    width: 48%;
  }
  #mypage ul.localNav li.withdraw {
    width: 48%;
  }
}
/* screen and (max-width:640px) */
/* -----------------------------------
	classList
----------------------------------- */
@media (max-width: 47.99em) {
  #mypage ul.classList li .data .date {
    height: auto;
    padding-top: 3px;
  }
}
/* screen and (max-width:640px) */
/* -----------------------------------
	delete
----------------------------------- */
@media (max-width: 47.99em) {
  #mypage .delete ul.localNav {
    margin-bottom: 10px;
  }
}
/* screen and (max-width:640px) */
/* ============================================================

	style info : 姉妹校のスタイルを記述

============================================================ */
#sisters .mapSection {
  position: relative;
  margin-bottom: 40px;
}
#sisters .mapSection .text {
  position: absolute;
  z-index: 120;
}
#sisters .mapSection .map {
  position: relative;
  z-index: 110;
  text-align: right;
}
#sisters .mapSection h2 {
  font-size: 115%;
  margin: 10px 0 20px 0;
}
#sisters .listSection table tr th,
#sisters .listSection table tr td {
  font-size: 86%;
  height: auto !important;
  text-align: left;
  vertical-align: top;
}
#sisters .listSection table tr th {
  width: 113px;
  padding: 0 17px 40px 30px;
}
#sisters .listSection table tr th.first {
  padding-left: 0;
}
#sisters .listSection table tr td {
  width: 225px;
  padding: 0 0 40px 0;
}
#sisters .listSection table tr th img {
  border: solid 1px #ddd;
}
#sisters .listSection h3 {
  margin-bottom: 10px;
}

ul.profLink {
  margin-top: 5px;
}
ul.profLink li {
  padding-left: 26px;
  white-space: nowrap;
}
ul.profLink li.web {
  background: url(../../img/common/icn_web.gif) left 4px no-repeat;
}
ul.profLink li.blog {
  background: url(../../img/common/icn_blog.gif) left 4px no-repeat;
}
ul.profLink li.twitter {
  background: url(../../img/common/icn_twitter.gif) 10px 4px no-repeat;
}
ul.profLink li.facebook {
  background: url(../../img/common/icn_facebook.gif) 10px 4px no-repeat;
}

@media (max-width: 47.99em) {
  #sisters .mapSection {
    margin-bottom: 20px;
  }
  #sisters .mapSection .text {
    position: relative;
  }
  #sisters .mapSection .text br {
    display: none;
  }
  #sisters .mapSection .text h2 {
    font-size: 100%;
    margin: 10px 0 8px 0;
  }
  #sisters .mapSection .text p {
    font-size: 86%;
  }
  #sisters .mapSection .map {
    margin-top: 15px;
    text-align: left;
  }
  #sisters .mapSection .map img {
    width: 100%;
  }
  #sisters .listSection table {
    position: relative;
    display: block;
    overflow: hidden;
    width: auto;
    margin: -5px -10px 0 -10px;
    padding: 0;
  }
  #sisters .listSection table tbody {
    display: block;
    width: auto;
    padding: 0;
  }
  #sisters .listSection table tr {
    display: block;
    width: auto;
    padding: 0;
  }
  #sisters .listSection table tr th,
  #sisters .listSection table tr td {
    display: block;
    width: auto;
    padding: 0;
  }
  #sisters .listSection table tr th.empty,
  #sisters .listSection table tr td.empty {
    display: none;
  }
  #sisters .listSection table tr th {
    float: left;
    clear: both;
    width: 29%;
    margin: 10px 4% 10px 10px !important;
  }
  #sisters .listSection table tr td {
    padding: 10px;
    border-top: solid 1px #efefef;
  }
  #sisters .listSection table tr:first-child th:first-child + td {
    border-top: none;
  }
  #sisters .listSection table tr td ul.profLink {
    overflow: hidden;
  }
}
/* screen and (max-width:640px) */
/* ============================================================

	style info : 寄付・サポート画面のスタイルを記述

============================================================ */
/* -----------------------------------
	index
----------------------------------- */
/* screen and (max-width:640px) */
/* ============================================================

	style info : マップ画面のスタイルを記述

============================================================ */
#map .leadSection h1 {
  float: none;
}
#map .leadSection h1 span {
  font-size: 36%;
  margin-left: 20px;
}
#map .mapSection {
  padding-top: 30px;
  border-top: solid 3px #efefef;
}
#map .mapSection .inner {
  padding: 20px 29px 29px 29px;
  border: solid 1px #cacaca;
}
#map .mapSection dl {
  margin-bottom: 12px;
}
#map .mapSection dl dt,
#map .mapSection dl dd {
  font-weight: bold;
  display: inline-block;
  vertical-align: middle;
  color: #222;
}

.ieOld #map .mapSection dl dt,
.ieOld #map .mapSection dl dd {
  display: inline;
  zoom: 1;
}

#map .mapSection dl dd {
  margin: 0 15px 0 7px;
}
#map .mapSection ul {
  margin-bottom: 12px;
}
#map .mapSection ul li {
  font-weight: bold;
  display: inline-block;
  margin-right: 15px;
  vertical-align: middle;
  color: #222;
}
#map .mapSection ul li a {
  padding: 4px 0 3px 23px;
  text-decoration: none;
  background: url(../../img/common/icn_map_check.png) 0 center no-repeat;
}

.ieOld #map .mapSection ul li {
  display: inline;
  zoom: 1;
}

#map .mapSection ul li.classroom a {
  background-position: -200px center;
}
#map .mapSection ul li.classroom a.off {
  background-position: 0 center;
}
#map .mapSection ul li.shop a {
  background-position: -600px center;
}
#map .mapSection ul li.shop a.off {
  background-position: -400px center;
}
#map .mapSection ul li a.off {
  opacity: 0.7;
  filter: alpha(opacity=70);
  -moz-opacity: 0.7;
}

#shibuyaMap {
  width: 680px;
  height: 680px;
}
#shibuyaMap .mapContents {
  line-height: 1.5;
  width: 240px;
  padding: 5px 0;
}
#shibuyaMap .mapContents a {
  text-decoration: none;
}
#shibuyaMap .mapContents .pic {
  float: left;
  margin-right: 10px;
}
#shibuyaMap .mapContents .text {
  overflow: hidden;
}
#shibuyaMap .mapContents h2 {
  font-size: 93%;
}
#shibuyaMap .classroomInfo h2 {
  margin-bottom: 5px;
  color: #00b458;
}
#shibuyaMap .shopInfo h2 {
  margin-bottom: 10px;
  color: #f51880;
}
#shibuyaMap .mapContents h3 {
  font-size: 79%;
  margin-bottom: 5px;
  color: #e30827;
}
#shibuyaMap .mapContents p.desc {
  font-size: 86%;
}
#shibuyaMap .mapContents p.address {
  font-size: 72%;
  margin-top: 10px;
}

#map .shopSection,
#map .classroomSection {
  margin-top: 30px;
  padding-top: 20px;
  border-top: solid 3px #efefef;
}
#map .shopSection .arwLink a {
  font-size: 100%;
  color: #00b458 !important;
  background-position: left center;
}
#map .classroomSection .arwLink a {
  font-size: 100%;
  color: #00b458 !important;
  background-position: left center;
}
#map .classroomSection .classroomList li .pic {
  line-height: 158px;
}
#map .classroomSection .classroomList li .pic img {
  width: 100%;
  vertical-align: middle;
}

@media (max-width: 47.99em) {
  #map .leadSection h1 span {
    font-size: 12px;
    display: block;
    margin: 5px 0 0 0;
  }
  #map .mapSection {
    padding-top: 5px;
    border-top: none;
  }
  #map .mapSection .inner {
    padding: 10px;
  }
  #map .mapSection ul li img {
    width: auto;
  }
  #shibuyaMap {
    width: 100%;
    height: 300px;
  }
  #shibuyaMap .mapContents {
    width: 200px;
  }
  #shibuyaMap .mapContents .pic {
    width: 30%;
  }
  #shibuyaMap .mapContents .pic img {
    width: 100%;
  }
  #map .shopSection,
  #map .classroomSection {
    margin-top: 20px;
    padding-top: 10px;
  }
  #map .shopSection h2 {
    margin-bottom: 0;
  }
  #map .shopSection p.arwLink {
    margin-top: 10px;
  }
  #map .classroomSection {
    overflow: hidden;
    margin-bottom: 10px;
  }
}
/* screen and (max-width:640px) */
/* ============================================================

	style info : フッタメニュー系画面のスタイルを記述

============================================================ */
/* -----------------------------------
	guide
----------------------------------- */
#guide table tr td {
  padding: 0 10px 10px 0;
  text-align: left;
  vertical-align: top;
}
#guide table tr td p {
  font-size: 86%;
  margin: 3px 0 0 0;
}

@media (max-width: 47.99em) {
  #guide table {
    display: block;
  }
  #guide table tbody,
  #guide table tr {
    display: block;
  }
  #guide table td {
    display: block;
  }
  #guide table td img {
    width: auto;
  }
}
/* screen and (max-width:640px) */
/* -----------------------------------
	sitemap
----------------------------------- */
#sitemap .sitemapSection {
  display: flex;
  flex-wrap: wrap;
  border-top: 3px solid #efefef;
}
#sitemap .sitemapSection h2 {
  font-size: 115%;
  padding: 26px 0 6px;
}
#sitemap .sitemapSection h2.top {
  padding: 24px 0 6px;
}
#sitemap .sitemapSection h2 a {
  padding-left: 20px;
  text-decoration: none;
  background: url(../../img/common/arw_basic_grn03_r.png) 0 0.2em no-repeat;
}
#sitemap .sitemapSection .colR,
#sitemap .sitemapSection .colL {
  width: 100%;
  padding-right: 0.5em;
  padding-left: 0.5em;
}
@media (min-width: 62em) {
  #sitemap .sitemapSection .colR,
  #sitemap .sitemapSection .colL {
    max-width: 40%;
  }
}
#sitemap .sitemapSection .colR h2 {
  padding: 8px 0 6px;
}
#sitemap .sitemapSection .colR .category {
  padding: 18px 20px 0 0;
}
#sitemap .sitemapSection ul {
  padding-left: 20px;
}
#sitemap .sitemapSection ul li {
  font-size: 86%;
}

@media (max-width: 47.99em) {
  #sitemap .sitemapSection {
    margin-bottom: 20px;
    padding-left: 20px;
  }
  #sitemap .sitemapSection h2 {
    font-size: 100%;
    padding: 1em 0 0.5em 0;
  }
  #sitemap .sitemapSection h2.top {
    padding: 1em 0 0.5em 0;
  }
  #sitemap .sitemapSection .colR .category {
    margin: 1em 0;
    padding: 0;
  }
}
/* screen and (max-width:640px) */
/* -----------------------------------
	reportSection ＊2015bunkasai用だが汎用性があるためcommon
----------------------------------- */
ul.reportGridList {
  position: relative;
  margin-right: -30px;
}
ul.reportGridList li {
  line-height: 1.2;
  position: relative;
  display: inline;
  float: left;
  width: 355px;
  margin: 0 30px 40px 0;
}
ul.reportGridList li .itemInner {
  position: relative;
  border: solid 2px #dfdfdf;
}
ul.reportGridList li a {
  text-decoration: none;
}
ul.reportGridList li p.badge {
  position: absolute;
  z-index: 200;
  top: -15px;
  left: 276px;
  width: 71px;
  height: 71px;
  text-align: center;
  color: #fff;
  background: url(../../img/common/bg_badge.png) 0 0 no-repeat;
}
ul.reportGridList li .badgeInner {
  font-weight: bold;
  display: block;
  margin-top: 21px;
}
ul.reportGridList li .pic {
  position: relative;
  z-index: 100;
  margin: -2px -2px 0;
}
ul.reportGridList li .picInner {
  overflow: hidden;
  height: 200px;
}
ul.reportGridList li.small .picInner {
  box-sizing: border-box;
  text-align: center;
  border: 2px solid #dfdfdf;
  background-color: #fff;
}
ul.reportGridList li .pic img {
  width: 100%;
}
ul.reportGridList li.small .pic img {
  width: auto;
  height: 100%;
  margin: 0 auto;
}
ul.reportGridList li .title {
  padding-top: 8px;
}
ul.reportGridList li h3 {
  font-size: 157%;
  line-height: 1.4;
  padding: 6px 22px 0;
  text-align: left;
}
ul.reportGridList li p.classTitle {
  font-size: 107%;
  font-weight: bold;
  line-height: 1.4;
  padding: 6px 22px 14px;
}
ul.reportGridList li p.desc {
  font-size: 93%;
  line-height: 1.7;
  padding: 14px 22px 22px;
  color: #b4b4b4;
  color: #000;
  background-color: #f2f2f2;
}

@media (max-width: 47.99em) {
  ul.reportGridList {
    margin: 0;
  }
  ul.reportGridList li {
    width: 100%;
    margin: 0 0 30px;
  }
}
/* ============================================================

	style info : 英語版のスタイルを記述

============================================================ */
/* -----------------------------------
	common
----------------------------------- */
#english .cf {
  overflow: hidden;
}
#english .ftLeft {
  float: left;
}
#english .ftRight {
  float: right;
}
#english .mb10 {
  margin-bottom: 10px !important;
}
#english .mb15 {
  margin-bottom: 15px !important;
}
#english .mt20 {
  margin-top: 20px !important;
}
#english .mt30 {
  margin-top: 30px !important;
}
#english .contentsArea {
  position: relative;
  display: block;
  width: 100%;
  max-width: 740px;
  margin-right: auto;
  margin-left: auto;
}
#english .pageNavi {
  clear: both;
  text-align: right;
}
#english .pamph {
  position: relative;
  margin-bottom: 7em;
}
#english #pamph01 .inner {
  height: 400px;
  text-align: center;
  background-image: url(../../img/english/pamph01.jpg);
  background-repeat: no-repeat;
  background-position: center;
}
#english #pamph01 p {
  padding: 260px 0 0 24px;
}
#english #pamph02 .lead {
  float: left;
  padding-bottom: 10px;
}
#english .pamph02R {
  float: right;
  width: 300px;
  padding-bottom: 10px;
}
#english .pamph02L {
  float: left;
  width: 340px;
  padding-bottom: 10px;
}
#english #pamph03 .pamph0R {
  position: absolute;
  top: 0;
  right: 0;
  width: 311px;
  height: 185px;
  background: url(../../img/english/pamph_map.gif) no-repeat right top;
}
#english .pamph0L {
  float: left;
  margin-bottom: 10px;
  padding-bottom: 10px;
  /* 	margin-left: 10px; */
}
#english #pamph04 .pamph0R {
  position: absolute;
  top: 0;
  right: 0;
  width: 311px;
}
#english #pamph04 .pamph0R p.pict {
  width: 324px;
  height: 255px;
  background: url(../../img/english/pamph04_1pict.gif) no-repeat right top;
}
#english #pamph04 .pamph0R p.text {
  width: 323px;
  height: 145px;
  background: url(../../img/english/pamph04_2ex.gif) no-repeat right top;
}
#english #pamph05 {
  background-image: url(../../img/english/pamph05pict.gif);
  background-repeat: no-repeat;
  background-position: right center;
}
#english #pamph06 {
  width: 740px;
  height: 366px;
  background-image: url(../../img/english/pamph06pict.gif);
  background-repeat: no-repeat;
  background-position: right center;
}
#english #pamph07,
#english #pamph09 .ftRight {
  text-align: center;
}
#english #pamph10 .inner {
  margin-top: 13px;
  margin-bottom: 20px;
  margin-left: 10px;
  padding-bottom: 20px;
}
#english .contactSection .name {
  font-family: "Lato", sans-serif;
}

/* -----------------------------------
	index
----------------------------------- */
/* ============================================================

	style info : 404ページのスタイルを記述

============================================================ */
.fullWidthSection {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  width: 100%;
  margin-bottom: 57px;
  padding: 55px 5%;
  border: 5px solid #efefef;
  -o-box-sizing: border-box;
  -ms-box-sizing: border-box;
}

#notFound .fullWidthSection {
  text-align: center;
}
#notFound h1 {
  font-family: "Lato", sans-serif;
  font-size: 286%;
  line-height: 1;
  margin-bottom: 0.6em;
  text-align: center;
  color: #00b458;
}
#notFound p {
  font-size: 93%;
  line-height: 1.9;
}
#notFound .txt {
  line-height: 1;
  margin-bottom: 2.2em;
}
#notFound #extra {
  display: none;
}

/* ============================================================

	project.css 上書き

============================================================ */
#main .navSection .categoryList {
  position: relative;
  display: block;
  float: none;
  margin-bottom: 1rem;
  margin-left: 0;
  padding-top: 0;
}
@media (min-width: 62em) {
  #main .navSection .categoryList {
    float: right;
  }
}
#main .navSection .categoryList .archiveBg,
#main .navSection .categoryList select {
  display: block;
  width: 100%;
  min-width: 100%;
  margin-right: auto;
  margin-left: auto;
}
#main .navSection .categoryList select {
  position: relative;
  top: 0;
}
#main .navSection .categoryList .archiveBg {
  position: absolute;
  top: 0;
  width: 100% !important;
  pointer-events: none;
}
#main .navSection .pager {
  display: block;
}

#cic .layoutInner {
  width: 100%;
  margin-right: auto;
  margin-left: auto;
}

ol.ol-kana {
  padding-left: 2.5em;
}

ol.ol-kana li {
  list-style: katakana outside;
}

ol.ol-bracketNum li {
  list-style-type: none;
  counter-increment: cnt;
  text-indent: -1em;
  padding: 0;
}

ol.ol-bracketNum li {
  list-style-type: none;
  text-indent: -1.5em;
  padding: 0;
}

ol.ol-bracketNum li:before {
  display: marker;
  content: "(" counter(cnt) ")";
}