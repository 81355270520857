/**
 * Variables
 */

//** Colors */

// theme
$color-main: #00b458;
$color-base: #fff;
$color-text: #333;
$body-color: $color-text;

// module
$color-bg-circle: #f1f1f1;
$color-bg-sns-hover: #ebfff5;
$color-bg-status: #ecedec;
$color-bg-step: #f8ef5b;
$color-border-light: #e4e3e3;
$color-border: #878888;
$color-main-light: #b7fd84;
$color-text-light: #8c8c8c;
$color-text-warning: #fc6a23;
$color-error: #ee102a;

// main gradation
$color-main-grad: linear-gradient(
  to right,
  rgba($color-main, 1) 0%,
  rgba(2, 212, 104, 1) 100%
);

// sns color
$color-twitter: #00aced;
$color-facebook: #3b5998;
$color-line: #00c300;
$color-instagram: #d7464f;
$color-instagram-gradient: radial-gradient(
  circle farthest-corner at 35% 90%,
  #fec564,
  transparent 50%
),
  radial-gradient(circle farthest-corner at 0 140%, #fec564, transparent 50%),
  radial-gradient(ellipse farthest-corner at 0 -25%, #5258cf, transparent 50%),
  radial-gradient(ellipse farthest-corner at 20% -50%, #5258cf, transparent 50%),
  radial-gradient(ellipse farthest-corner at 100% 0, #893dc2, transparent 50%),
  radial-gradient(ellipse farthest-corner at 60% -20%, #893dc2, transparent 50%),
  radial-gradient(ellipse farthest-corner at 100% 100%, #d9317a, transparent),
  linear-gradient(#6559ca, #bc318f 30%, #e33f5f 50%, #f77638 70%, #fec66d 100%);

//** Fonts */

// font famiry
$font-sans-serif: -apple-system, BlinkMacSystemFont, "Hiragino Sans",
  "Hiragino Kaku Gothic ProN", CustomYuGothicM, Meiryo;
$font-google-lato: "Lato";
$font-hiragino-pro-w6: "Hiragino Kaku Gothic Pro";
$font-family-base: $font-google-lato, $font-sans-serif, sans-serif;
$font-heading: $font-google-lato, $font-hiragino-pro-w6, $font-sans-serif,
  sans-serif;

// Font size
$base-font-size: 16px;
$font-tracking: 0.06em;
$font-weight-normal: 400;
$font-weight-bold: 700;

$heading-scale-ratio: 2.8; // amount of px headings grow from h6 to h1

//** Media Query */

// Breakpoints
$mq-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px
) !default;

// container max width
$container-max-width: (
  sm: 540px,
  md: 720px,
  lg: 960px,
  xl: 1140px
) !default;

//** Unit */

// Spacer
$spacer: 1rem;
$spacer-lg: 2.5%;
$spacer-xl: 5%;

// z-index
$z-header: 1000;
$z-search-modal: $z-header + 100;

// border-radius
$border-radius: 5px;
$button-round-radius: 80px;

// opacity
$hover-opacity: 0.7;

// box shadow
$box-shadow-card: 0 3px 6px rgba($color-text, 0.08),
  0 6px 32px rgba($color-text, 0.04);
$box-shadow-card-lg: 0 4px 8px rgba($color-text, 0.1),
  0 8px 42px rgba($color-text, 0.08);
$box-shadow-header: 0 1px 3px rgba(black, 0.07), 0 3px 16px rgba(black, 0.03);

//** Easing function */

// Cubic
$easeInCubic: cubic-bezier(0.55, 0.055, 0.675, 0.19);
$easeOutCubic: cubic-bezier(0.215, 0.61, 0.355, 1);
$easeInOutCubic: cubic-bezier(0.645, 0.045, 0.355, 1);
// Circ
$easeInCirc: cubic-bezier(0.6, 0.04, 0.98, 0.335);
$easeOutCirc: cubic-bezier(0.075, 0.82, 0.165, 1);
$easeInOutCirc: cubic-bezier(0.785, 0.135, 0.15, 0.86);
// Expo
$easeInExpo: cubic-bezier(0.95, 0.05, 0.795, 0.035);
$easeOutExpo: cubic-bezier(0.19, 1, 0.22, 1);
$easeInOutExpo: cubic-bezier(1, 0, 0, 1);
// Quad
$easeInQuad: cubic-bezier(0.55, 0.085, 0.68, 0.53);
$easeOutQuad: cubic-bezier(0.25, 0.46, 0.45, 0.94);
$easeInOutQuad: cubic-bezier(0.455, 0.03, 0.515, 0.955);
// Quart
$easeInQuart: cubic-bezier(0.895, 0.03, 0.685, 0.22);
$easeOutQuart: cubic-bezier(0.165, 0.84, 0.44, 1);
$easeInOutQuart: cubic-bezier(0.77, 0, 0.175, 1);
// Quint
$easeInQuint: cubic-bezier(0.755, 0.05, 0.855, 0.06);
$easeOutQuint: cubic-bezier(0.23, 1, 0.32, 1);
$easeInOutQuint: cubic-bezier(0.86, 0, 0.07, 1);
// Sine
$easeInSine: cubic-bezier(0.47, 0, 0.745, 0.715);
$easeOutSine: cubic-bezier(0.39, 0.575, 0.565, 1);
$easeInOutSine: cubic-bezier(0.445, 0.05, 0.55, 0.95);
// Back
$easeInBack: cubic-bezier(0.6, -0.28, 0.735, 0.045);
$easeOutBack: cubic-bezier(0.175, 0.885, 0.32, 1.275);
$easeInOutBack: cubic-bezier(0.68, -0.55, 0.265, 1.55);

// icons
$icomoon-font-family: "icons" !default;
$icomoon-font-path: "../fonts" !default;

$icon-user: "\e909";
$icon-arrow-circle: "\e900";
$icon-corporate: "\e901";
$icon-home: "\e902";
$icon-mail: "\e903";
$icon-phone: "\e904";
$icon-school: "\e905";
$icon-smartphone: "\e906";
$icon-sns-face-book: "\e907";
$icon-sns-twitter: "\e908";
